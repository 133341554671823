/* -----------------------------------------------------------------------------------

 01. Google fonts
 02. Basics style
 03. Helper style - [NEW]
 04. Selection style 
 05. Owl-Theme custom style
 06. Section style
 07. Navbar style
 08. Header style
 09. Slider style
 10. Slider Left Panel style
 11. Page Banner Header style
 12. Content Lines style
 13. Video Background - [NEW] 
 14. About style 
 15. Team style
 16. Team Details style
 17. Careers style - [NEW]
 18. Services style
 19. Services2 style - [NEW]
 20. Services3 style - [NEW]
 21. Services4 style - [NEW]
 22. Projects style
 23. Projects2 (masonry) style
 24. Projects3 style
 25. Projects4 style
 26. Project Gallery style
 27. Project Page style - [NEW]
 28. Clients style
 29. Gallery style
 30. Promo Video and Testimonials style
 31. Blog 1 style
 32. Blog 2 style
 33. Blog 3 style
 34. Blog 4 style - [NEW]
 35. Post style
 36. Contact style
 37. Buttons style
 38. Footer style
 39. Corner style
 40. Preloader style
 41. toTop Button style
 42. Accordion Box (for Faqs) style
 43. Pricing plan style
 44. Error Page - 404 style
 45. Process style
 46. Process 2 style - [NEW]
 47. Comming soon style
 48. Overlay Effect Bg image style - [NEW]
 49. Responsive (Media Query)
 
----------------------------------------------------------------------------------- */


/* ======= Google fonts (Tajawal & Tajawal) ======= */

/* @import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Tajawal:wght@200;300;400;500;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap'); */
@font-face {
    font-family: 'Tajawal';
    src: url('./assets/fonts/Tajawal-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    }
/* ======= Basic style ======= */
:root {
    --main-o-color: #0982ac; /* Define your color value here */
  }
  
html,
body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    list-style: none;
    word-wrap: break-word;
    font-family: "Tajawal", sans-serif;
}

body {
    font-family: 'Tajawal', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75em;
    color: #777;
    overflow-x: hidden !important;
    background: #fff;
}

p {
    font-family: 'Tajawal', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75em;
    color: #777;
    margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
    line-height: 1.25em;
    margin: 0 0 20px 0;
}

img {
    width: 100%;
    height: auto;
}

img {
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    color: #323232;
}

span,
a,
a:hover {
    display: inline-block;
    text-decoration: none;
    color: inherit;
}

b {
    font-weight: 400;
    color: #0982ac;
}


/* text field */

button,
input,
optgroup,
select,
textarea {
    font-family: 'Tajawal', sans-serif;
}

input[type="password"]:focus,
input[type="email"]:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
textarea:focus {
    outline: none;
}

input[type="password"],
input[type="email"],
input[type="text"],
input[type="file"],
textarea {
    max-width: 100%;
    margin-bottom: 10px;
    padding: 10px 10px 10px 0px;
    height: auto;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-width: 0 0 1px;
    border-style: solid;
    display: block;
    width: 100%;
    line-height: 1.5em;
    font-family: 'Tajawal', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #777;
    background-image: none;
    border-bottom: 1px solid #f4f4f4;
    border-color: ease-in-out .15s, box-shadow ease-in-out .15s;
}

input:focus,
textarea:focus {
    border-bottom-width: 1px;
    border-color: #0982ac;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: 1.75em;
    -webkit-transition: background-color .15s ease-out;
    transition: background-color .15s ease-out;
    background: #0982ac;
    border: 1px solid transparent;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover {
    background-color: #323232;
    color: #fff;
    border: 1px solid transparent;
}

select {
    padding: 10px;
    border-radius: 5px;
}

table,
th,
tr,
td {
    border: 1px solid #ececec;
}

th,
tr,
td {
    padding: 10px;
}

input[type="radio"],
input[type="checkbox"] {
    display: inline;
}


/* submit and alert success  */
input[type="submit"] {
    font-weight: 300;
    font-family: 'Tajawal', sans-serif;
    text-transform: uppercase;
    background: #0982ac;
    color: #fff;
    padding: 8px 24px;
    margin: 0;
    position: relative;
    font-size: 16px;
    letter-spacing: 3px;
}
.alert-success {
    background: transparent;
    color: #777;
    border: 1px solid #0982ac;
    border-radius: 0px;
}
/* Placeholder */
::-webkit-input-placeholder {
    color: #777;
    font-size: 15px;
    font-weight: 400;
}
:-moz-placeholder {
    color: #777;
}
::-moz-placeholder {
    color: #777;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #777;
}



/* blockquote */
blockquote {
    padding: 45px;
    display: block;
    position: relative;
    background-color: #f4f4f4;
    overflow: hidden;
    margin: 35px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75em;
    color: #777;
}
blockquote p {
    font-family: inherit;
    margin-bottom: 0 !important;
    color: inherit;
    max-width: 650px;
    width:100%;
    position:relative;
    z-index:3;
}
blockquote:before {
    content: '\e645';
    font-family: 'Themify';
    position: absolute;
    right: 40px;
    bottom: 40px;
    font-size: 110px;
    opacity: 0.07;
    line-height: 1;
    color: #272727;
}
blockquote p {
    margin-bottom: 0;
}
blockquote p a {
    color:inherit;
}
blockquote cite {
    display:inline-block;
    font-size: 16px;
    position:relative;
    padding-left: 60px;
    border-color:inherit;
    line-height:1;
    margin-top: 22px;
    font-style:normal;
    color: #0982ac;
    font-weight: 400;
}
blockquote cite:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 5px;
    width: 45px;
    height: 1px;
    border-top: 1px solid; 
    border-color:inherit;
    color: #0982ac;
}


.container {
    padding-right: 30px;
    padding-left: 30px;
}

.o-hidden {
    overflow: hidden;
}

.position-re {
    position: relative;
}

.full-width {
    width: 100%;
}

.bg-img {
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-fixed {
    background-attachment: fixed;
}

.pattern {
    background-repeat: repeat;
    background-size: auto;
}

.bold {
    font-weight: 600;
}

.count {
    font-family: 'Tajawal', sans-serif;
}

.valign {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.v-middle {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.js .animate-box {
    opacity: 0;
}

.map {	
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	}

:root { scroll-behavior: auto; }

/* ======= Helper style ======= */
.mt-0 {
  margin-top: 0 !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.no-padding {
  padding: 0 !important;
}



/* ======= Selection style ======= */

::-webkit-selection {
    color: #777;
    background: rgba(0, 0, 0, 0.1);
}

::-moz-selection {
    color: #777;
    background: rgba(0, 0, 0, 0.1);
}

::selection {
    color: #777;
    background: rgba(0, 0, 0, 0.1);
}


/* ======= Owl-Theme custom style ======= */

.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0px;
    line-height: .7;
    display: block;
}

.owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 0 3px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid #999;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: transparent;
    border: 1px solid #0982ac;
}

.owl-carousel .owl-nav button.owl-next, 
.owl-carousel .owl-nav button.owl-prev, 
.owl-carousel button.owl-dot {
    outline: none;
}

/* ======= Scrollbar ======= */

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: #0982ac;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: #0982ac;
}


/* ======= Sidebar styles ======= */

#bauen-page {
    width: 100%;
    overflow: hidden;
    position: relative;
}
/* toggle */
.headerToggle {
    transform: translateX(0px) !important;
}
/* end toggle */
#bauen-aside {
    padding: 40px;
    width: 20.9%;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    overflow-x: hidden;
    z-index: 1001;
    background: #fff;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    border: none;
    border-right: 1px solid #f4f4f4;
    scrollbar-width: none;
    /* my edit */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 1200px) {
    #bauen-aside {
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    #bauen-aside {
        width: 270px;
        -moz-transform: translateX(-270px);
        -webkit-transform: translateX(-270px);
        -ms-transform: translateX(-270px);
        -o-transform: translateX(-270px);
        transform: translateX(-270px);
        padding: 60px 30px 0 30px;
    }
}


/* logo */

#bauen-aside .bauen-logo {
    text-align: center;
    margin-bottom: 90px;
    display: block;
}

#bauen-aside .bauen-logo h2 {
    font-family: 'Tajawal', sans-serif;
    font-size: 45px;
    font-weight: 400;
    display: block;
    width: 100%;
    position: relative;
    color: #0982ac;
    letter-spacing: 9px;
    margin-bottom: 0;
}

#bauen-aside .bauen-logo span {
    display: block;
    font-family: 'Tajawal', sans-serif;
    font-size: 12px;
    color: #777;
    font-weight: 300;
    letter-spacing: 4px;
    display: block;
    margin-top: 5px;
    line-height: 1.5em;
    text-transform: uppercase;
    margin-right: 5px;
}

#bauen-aside .bauen-logo .logo-img {
    width: 75px;
    margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
    #bauen-aside .bauen-logo {
        margin-bottom: 60px;
    }
}

#bauen-aside .bauen-logo a {
    display: inline-block;
    text-align: center;
    color: #0982ac;
}

#bauen-aside .bauen-logo a span {
    display: block;
    margin-top: 0px;
}


/* Menu */

#bauen-aside .bauen-main-menu,
#bauen-aside .bauen-main-menu ul,
#bauen-aside .bauen-main-menu ul li,
#bauen-aside .bauen-main-menu ul li a {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#bauen-aside .bauen-main-menu {
    margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
    #bauen-aside .bauen-main-menu {
        margin-bottom: 30px;
    }
}

#bauen-aside .bauen-main-menu ul ul {
    display: none;
}

#bauen-aside .bauen-main-menu > ul > li > a {
    font-family: 'Tajawal', sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.25em;
    color: #777;
    margin: 13px 0 0 0;
    padding: 0 0 13px 0;
    list-style: none;
    border-bottom: 1px solid rgba(0,0,0,0.03);
}

#bauen-aside .bauen-main-menu > ul > li > a:hover,
#bauen-aside .bauen-main-menu > ul > li.active > a,
#bauen-aside .bauen-main-menu > ul > li.open > a {
    color: #0982ac;
}

#bauen-aside .bauen-main-menu > ul > li.open > a {
    font-family: 'Tajawal', sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.25em;
    color: #777;
    margin: 13px 0 0 0;
    padding: 0 0 13px 0;
    list-style: none;
    border-bottom: none;
}

#bauen-aside .bauen-main-menu > ul > li:last-child > a,
#bauen-aside .bauen-main-menu > ul > li.last > a {}

#bauen-aside .bauen-main-menu > ul > li > a:hover > span::after,
#bauen-aside .bauen-main-menu > ul > li.active > a > span::after,
#bauen-aside .bauen-main-menu > ul > li.open > a > span::after {
    border-color: #0982ac;
}

#bauen-aside .bauen-main-menu ul ul li a {
    padding: 8px 0 8px 15px;
    list-style: none;
    font-family: 'Tajawal', sans-serif;
    color: #777;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.25em;
}

#bauen-aside .bauen-main-menu ul ul li:hover > a,
#bauen-aside .bauen-main-menu ul ul li.open > a,
#bauen-aside .bauen-main-menu ul ul li.active > a {
    color: #0982ac;
}

#bauen-aside .bauen-main-menu ul ul ul li a {
    margin-left: 15px;
}

#bauen-aside .bauen-main-menu > ul > li > ul > li:last-child > a,
#bauen-aside .bauen-main-menu > ul > li > ul > li.last > a {
    padding-bottom: 8px;
}

#bauen-aside .bauen-main-menu > ul > li > ul > li.open:last-child > a,
#bauen-aside .bauen-main-menu > ul > li > ul > li.last.open > a {}

#bauen-aside .bauen-main-menu > ul > li > ul > li.open:last-child > ul > li:last-child > a {}

#bauen-aside .bauen-main-menu ul ul li.active > a::after,
#bauen-aside .bauen-main-menu ul ul li.open > a::after,
#bauen-aside .bauen-main-menu ul ul li > a:hover::after {}


/* footer */

#bauen-aside .bauen-footer {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 30px 0px 0px 0px;
    text-align: center;
}

#bauen-aside .bauen-footer p {
    font-size: 9px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: 'Tajawal', sans-serif;
    letter-spacing: 3px;
    font-weight: 300;
}

#bauen-aside .bauen-footer a {
    color: #0982ac;
}

@media screen and (max-width: 768px) {
    #bauen-aside .bauen-footer {}
}

#bauen-aside .bauen-footer ul {
    display: inline-block;
    margin-bottom: 15px;
    padding: 0;
    position: relative;
    text-align: center;
}

#bauen-aside .bauen-footer ul:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #0982ac;
    top: 50%;
    margin-top: -1px;
    right: 100%;
}

#bauen-aside .bauen-footer ul:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #0982ac;
    top: 50%;
    margin-top: -1px;
    right: auto;
    left: 100%;
}

#bauen-aside .bauen-footer ul li {
    display: inline-block;
    margin-right: 5px;
    border: 1px solid #ececec;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    transition: all 0.3 ease-in-out;
    border-radius: 50%;
}

#bauen-aside .bauen-footer ul li:last-child {
    margin-right: 0px
}

#bauen-aside .bauen-footer ul li:hover {
    border: 1px solid #0982ac;
}

#bauen-aside .bauen-footer ul li a {
    color: #777;
    font-size: 13px;
}

#bauen-aside .bauen-footer ul li a:hover,
#bauen-aside .bauen-footer ul li a:active,
#bauen-aside .bauen-footer ul li a:focus {
    text-decoration: none;
    outline: none;
    color: #0982ac;
}


/* important offcanvas for menu */

body.offcanvas {
    overflow-x: hidden;
}

body.offcanvas #bauen-aside {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    width: 270px;
    z-index: 999;
    position: fixed;
}

body.offcanvas #bauen-main,
body.offcanvas .bauen-nav-toggle {
    top: 0;
    -moz-transform: translateX(270px);
    -webkit-transform: translateX(270px);
    -ms-transform: translateX(270px);
    -o-transform: translateX(270px);
    transform: translateX(270px);
}


/* ======= Content styles ======= */

#bauen-main {
    width: 79.2%;
    float: right;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

@media screen and (max-width: 1200px) {
    #bauen-main {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    #bauen-main {
        width: 100%;
    }

    .container {
        padding-right: 30px;
        padding-left: 30px;
    }
}


/* ======= Navigation styles ======= */

#bauen-main-menu {
    margin-bottom: 90px;
}

.bauen-nav-toggle {
    cursor: pointer;
    text-decoration: none;
}

.bauen-nav-toggle.active i::before,
.bauen-nav-toggle.active i::after {
    background: #fff;
}

.bauen-nav-toggle.dark.active i::before,
.bauen-nav-toggle.dark.active i::after {
    background: #171717;
}

.bauen-nav-toggle:hover,
.bauen-nav-toggle:focus,
.bauen-nav-toggle:active {
    outline: none;
    border-bottom: none !important;
}

.bauen-nav-toggle i {
    position: relative;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    width: 20px;
    height: 1px;
    color: #fff;
    font: bold 14px/.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: #fff;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
    top: -5px;
}

.bauen-nav-toggle i::before,
.bauen-nav-toggle i::after {
    content: '';
    width: 20px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.bauen-nav-toggle.dark i {
    position: relative;
    color: #171717;
    background: #171717;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.bauen-nav-toggle.dark i::before,
.bauen-nav-toggle.dark i::after {
    background: #171717;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.bauen-nav-toggle i::before {
    top: -7px;
}

.bauen-nav-toggle i::after {
    bottom: -7px;
}

.bauen-nav-toggle:hover i::before {
    top: -10px;
}

.bauen-nav-toggle:hover i::after {
    bottom: -10px;
}

.bauen-nav-toggle.active i {
    background: transparent;
}

.bauen-nav-toggle.active i::before {
    top: 0;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.bauen-nav-toggle.active i::after {
    bottom: 0;
    -webkit-transform: rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
    -o-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
}

.bauen-nav-toggle {
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 9999;
    cursor: pointer;
    opacity: 1;
    visibility: hidden;
    padding: 11px 15px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: #0982ac;
    margin: 15px 30px;
    border-radius: 50%;
}

@media screen and (max-width: 768px) {
    #bauen-main-menu {
        margin-bottom: 60px;
    }

    .bauen-nav-toggle {
        opacity: 1;
        visibility: visible;
    }
}


/* ======= Section style ======= */

.section-padding {
    padding: 90px 0;
}

.section-padding2 {
    padding: 0 0 90px 0;
    position: relative;
}

.section-padding h6 {
    color: #0982ac;
    font-size: 20px;
    margin-bottom: 20px;
}

.section-title {
    font-size: 40px;
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
    color: #272727;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 10px;
    word-spacing: 5px;
    margin-bottom: 30px;
}

.section-title span {
    color: #0982ac;
}

.section-title2 {
    font-size: 35px;
    font-weight: 300;
    color: #0982ac;
    position: relative;
    line-height: 1.25em;
    margin-bottom: 30px;
    letter-spacing: 3px;
    text-transform: uppercase;
}


/* ======= Header style ======= */

.header {
    min-height: 100vh;
    overflow: hidden;
}

.header .caption .o-hidden {
    display: inline-block;
    padding-right: 60px;
}

.header .caption h2 {
    font-weight: 400;
    font-size: 30px;
}

.header .caption h4 {
    font-family: 'Tajawal', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #fff;
    text-align: right;
    margin-bottom: 30px;
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
}

.header .caption h1 {
    position: relative;
    margin-bottom: 20px;
    font-family: 'Tajawal', sans-serif;
    font-size: 48px;
    text-transform: uppercase;
    line-height: 1.35em;
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
    color: #fff;
    font-weight: 400;
    letter-spacing: 15px;
}

.header .caption p {
    font-size: 18px;
    line-height: 1.75em;
    margin-bottom: 0;
    color: #fff;
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
    margin-right: 15px;
}

.header .caption p span {
    letter-spacing: 5px;
    text-transform: uppercase;
    margin: 0 5px;
    padding-right: 14px;
    position: relative;
}

.header .caption p span:last-child {
    padding: 0;
}

.header .caption p span:last-child:after {
    display: none;
}

.header .caption p span:after {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 10px;
    right: 0;
    opacity: .5;
}

.header .caption .butn-light {
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
    margin-right: 15px;
}

.header .social a {
    color: #eee;
    font-size: 20px;
    margin: 10px 15px;
}


/* ======= Slider style ======= */

.slider .owl-item,
.slider-fade .owl-item {
    height: 100vh;
    position: relative;
}

.slider .item,
.slider-fade .item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.slider .item .caption,
.slider-fade .item .caption {
    z-index: 9;
}

.slider .owl-theme .owl-dots,
.slider-fade .owl-theme .owl-dots {
    position: absolute;
    bottom: 5vh;
    width: 100%;
}


/* owl-nav next and prev */

.owl-nav {
    position: absolute;
    bottom: 3%;
    left: 2%;
}

.owl-prev {
    float: left;
}

.owl-next {
    float: right;
}

.slider-fade .owl-theme .owl-nav [class*=owl-], .project-page .owl-theme .owl-nav [class*=owl-] {
    width: 40px;
    height: 40px;
    line-height: 34px;
    background: transparent;
    border-radius: 100%;
    color: rgba(255, 255, 255, 0.75);
    font-size: 11px;
    margin-right: 3px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 0.2s ease-in-out;
    transform: scale(1.0);
}

.owl-theme .owl-nav [class*=owl-]:hover {
    transform: scale(0.9);
    background: transparent;
    border: 1px solid #0982ac;
    color: #0982ac;
}

@media screen and (max-width: 768px) {
    .owl-nav {
        display: none;
    }
} 

/* ======= Page Banner Header style ======= */

.banner-header {
    height: 40vh;
    z-index: -2;
}

.banner-header h6 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
}

.banner-header h6 a:hover {
    color: #fff;
}

.banner-header h6 i {
    margin: 0 15px;
    font-size: 10px;
}

.banner-header h5 {
    color: #0982ac;
    font-size: 17px;
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
}

.banner-header h1 {
    font-size: 40px;
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
    color: #0982ac;
    position: relative;
    margin-bottom: 10px;
    line-height: 1.2em;
}

.banner-header p {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    color: #777;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.banner-header p a {
    color: #0982ac;
}

@media screen and (max-width: 767px) {
    .banner-header {
        height: 30vh;
    }

    .banner-header h6 {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .banner-header h6 a:hover {
        color: #0982ac;
    }

    .banner-header h6 i {
        margin: 0 15px;
        font-size: 10px;
    }

    .banner-header h5 {}

    .banner-header h1 {}

    .banner-header p {
        font-size: 20px;
        color: #777;
    }
}

.banner-img:after {
    position: absolute;
    height: 100%;
    right: 0;
    left: 0;
    content: "";
    background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, .8) 100%, rgba(255, 255, 255, 0.5) 100%);
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 100%, rgba(255, 255, 255, 0.5) 100%);
    object-fit: cover;
}


/* =======  Content Lines style  ======= */

.content-wrapper {
    position: relative;
}

.content-lines-wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -999;
}

.content-lines-inner {
    position: absolute;
    width: 1140px;
    left: 50%;
    margin-left: -570px;
    top: 0;
    height: 100%;
    border-left: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
}

.content-lines {
    position: absolute;
    left: 33.666666%;
    right: 33.666666%;
    height: 100%;
    border-left: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
}



/* =======  Video Background  ======= */
.video-fullscreen-wrap {
  height: 100vh;
  overflow: hidden;
}
.video-fullscreen-video {
    height: 100%;
}
.video-fullscreen-wrap video {
  width: 100vw;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.video-fullscreen-wrap .video-fullscreen-inner {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
}
.video-lauch-wrapper {
  border: none;
  background: none;
  width: 100%;
  padding: 0;
}
.video-fullscreen-wrap .video-fullscreen-inner h1 {
    position: relative;
    margin-bottom: 20px;
    font-family: 'Tajawal', sans-serif;
    font-size: 48px;
    text-transform: uppercase;
    line-height: 1.35em;
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
    color: #fff;
    font-weight: 400;
    letter-spacing: 15px;
}
.video-fullscreen-wrap .video-fullscreen-inner p {
    font-size: 18px;
    line-height: 1.75em;
    margin-bottom: 0;
    color: #fff;
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
    margin-right: 15px;
}
.video-fullscreen-wrap .video-fullscreen-inner .butn-light {
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
    margin-right: 15px;
}
.position-relative {
  position: relative !important;
}
@media screen and (max-width: 767px) {
    .video-fullscreen-wrap .video-fullscreen-inner h1 {
        font-size: 30px;
        margin-bottom: 15px;
        letter-spacing: 10px;
        line-height: 1.35em;
    }
    .video-fullscreen-wrap .video-fullscreen-inner p {
    font-size: 15px;
    margin-right: 10px;
    }
}



/* ======= About style ======= */

.about {
    padding-bottom: 90px;
}

.about .about-img img:hover {
    transform: scale(0.95);
}

.about .about-img {
    position: relative;
}

.about .about-img .img {
    padding: 0 0 30px 30px;
    position: relative;
}

.about .about-img .img:before {
    content: '';
    position: absolute;
    top: 30px;
    left: 0;
    right: 45px;
    bottom: 0;
    border: 1px solid #f4f4f4;
}

.about .about-img .img img {
    position: relative;
    z-index: 2;
    background: #fff;
}

.about-img-2 {
    position: relative
}

.about-img .about-img-2 {
    width: 40% !important;
    position: absolute;
    bottom: 0px;
    right: 45px;
    z-index: 3;
    padding: 20px 15px;
    color: #272727;
    text-transform: uppercase;
    font-family: 'Tajawal', sans-serif;
    font-weight: 300;
    letter-spacing: 5px;
    font-size: 13px;
    line-height: 1.5em;
    text-align: center;
}

.about-buro {
    background: #f4f4f4;
}


/* ======= Team style ======= */

.team .item {
    margin-bottom: 30px;
}

.team .item:hover .info .social {
    top: 0;
}

.team .info {
    text-align: center;
    padding: 30px 20px;
    position: relative;
    overflow: hidden;
}

.team .info h6 {
    font-size: 17px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 3px;
    color: #272727;
}

.team .info p {
    margin-bottom: 5px;
    color: #777;
}

.team .info .social {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    -webkit-transition: all .4s;
    transition: all .4s;
}

.team .info .social a {
    font-size: 15px;
    color: #0982ac;
    margin: 0 5px;
}

.team .info .social a:hover {
    color: #272727;
}



/* ======= Team Details style ======= */
.team-details {
    color: #777;
}
.team-details h3 {
    font-size: 25px;
    margin-bottom: 0px;
    color: #272727;
    letter-spacing: 3px;
    font-weight: 300;
    text-transform: uppercase;
}
.team-details .content {
    background-color: #f4f4f4;
}
.team-details .text-content {
    position: relative;
    padding: 30px 15px;
}
.team-details .content .team-details-info {
    padding: 10px 0 30px 0;
    position: relative;
}
.team-details .content .info-list {
    margin: 0;
    padding: 0
}
.team-details .content .info-list li {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    display: block;
    float: left;
     list-style: square;
    padding: 5px 0;
    display: inline-block;
    width: calc(48% - 1px)
}
.team-details .content .info-list .list-title {
     font-family: 'Tajawal', sans-serif;
    text-indent: 0;
    width: auto;
    display: inline;
    margin-right: 3px;
    position: relative;
    color: #0982ac;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px
    
}
.team-details .content .info-list .list-description {
    display: inline
}
.team-details .content ul.social-icons {
    margin: 0;
    padding: 0
}
.team-details .content .social-icons li {
    display: inline-block;
    border: none;
    z-index: 1;
    position: relative
}
.team-details .content .social-icons li {
    margin: 0 2px
}
.team-details .content .social-icons li:last-child {
    margin-right: 0
}
.team-details .content .social-icons li a {
    display: block;
    min-width: 26px;
    text-align: center
}
.team-details .content .social-icons.circle li>a {
    border-width: 1px;
    border-style: solid;
    height: 33px;
    width: 33px;
    line-height: 33px;
    text-align: center;
    display: block;
    background-color: transparent;
    font-size: 14px;
    border-radius: 50%
}
.team-details .content .social-icons.square li {
    margin: 0 0
}
.team-details .content .social-icons.square li a {
    font-size: 12px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    color: inherit;
    margin-right: 8px;
    border: 1px solid #d4d4d4;
    color: #777;
    border-radius: 50%;
}
.team-details .content .social-icons.square li a:hover {
  border: 1px solid #0982ac;
}
@media only screen and (max-width: 480px) {
.team-details .content .info-list li {
    width: 100%;
}
}



/* ======= Careers style ======= */
.position {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: -1px;
    padding: 40px 45px 40px 50px;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    background-color: transparent;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}
.position-link {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}
.position-title {
    font-family: 'Tajawal', sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 3px;
    text-transform: uppercase;
    position: relative;
    -webkit-transition: color .3s;
    transition: color .3s;
    -webkit-flex-basis: 30%;
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
    color: #272727;
}
.position-title span {
    font-family: 'Tajawal', sans-serif;
    color: #777;
    display: block;
    text-transform: none;
    letter-spacing: 0px;
}
.position-location {
    font-family: 'Tajawal', sans-serif;
    font-size: 16px;
    -webkit-transition: color .3s;
    transition: color .3s;
    -webkit-flex-basis: 40%;
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
    color: #272727;
}
.position-location span {
    font-family: 'Tajawal', sans-serif;
    color: #777;
    display: block;
    text-transform: none;
    letter-spacing: 0px;
}
.position-time {
    font-weight: 400;
    -webkit-transition: color .3s;
    transition: color .3s;
    color: #777;
}
.position-time span {
    font-family: 'Tajawal', sans-serif;
    color: #272727;
    display: block;
    text-transform: none;
    letter-spacing: 0px;
}
.position-icon {
    font-size: 16px;
    line-height: 1;
    color: #272727;
}
.position-icon i {
    display: inline-block
}
/* hover */
.position:hover {
    background-color: #0982ac;
}
.position:hover .position-title,
.position:hover .position-title span,
.position:hover .position-location,
.position:hover .position-location span,
.position:hover .position-time,
.position:hover .position-time span,
.position:hover .position-icon {
    color: #fff;
}
@media only screen and (max-width:991px) {
    .position {
        flex-direction: column;
        -webkit-box-pack: initial;
        -webkit-justify-content: initial;
        -ms-flex-pack: initial;
        justify-content: initial;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }
    .position-title {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
    .position-location {
        margin-top: 15px;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
    .position-time {
        margin-top: 15px;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
    .position-icon {
        display: none
    }
}


/* ======= Services style ======= */

.services {}

.services .item {
    padding: 60px 30px;
    margin-bottom: 30px;
    position: relative;
}

.services .item:hover {
    background: #f4f4f4;
}

.services .item:hover .numb {
    color: transparent;
    -webkit-text-stroke: 1px rgba(9, 130, 172, 0.5);
}

.services .item h5 {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    color: #272727;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.services .item p {
    margin-bottom: 0;
}

.services .item img {
    width: 60px;
    margin-bottom: 20px;
}

.services .item .numb {
    font-size: 40px;
    font-weight: 600;
    font-family: 'Tajawal', sans-serif;
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: transparent;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.1);
    line-height: 1em;
}


/* line animation */

.services .item .line {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
}

.services .item .line:after {
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 50px;
    height: 1px;
    background-color: #f4f4f4;
    content: '';
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}

.services .item:hover .line:after {
    width: 100%;
    background-color: rgba(9, 130, 172, 0.5);
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: ease-in-out;
}




/* ======= Services 2 ======= */
.services2 {
    text-align: center;
    position: relative;
    padding-bottom: 30px
}
.services2 .title-box {
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: 0;
    z-index: 2;
    background-color: #F4F4F4;
    padding: 20px 0;
    opacity: 1;
    visibility: visible;
    margin-bottom: 0;
    color: #777;
}
.services2 .title-box h3 {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    color: #272727;
    text-transform: uppercase;
    letter-spacing: 5px;
}
.services2 .services2-img {
    position: relative
}
.services2 .services2-img:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;
    background-color: #1a1a1a;
    opacity: 0.7;
    visibility: hidden
}
.services2 .services2-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 20px;
    margin-top: 50px;
    opacity: 0;
    visibility: hidden
}
.services2 .icon img {
    width: 60px;
    color: #fff;
    margin-bottom: 20px;
}
.services2 .services2-title {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 15px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 5px;
}
.services2 .services2-subtitle {
    color: #0982ac;
    font-family: 'Tajawal', sans-serif;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.services2 .services2-text {
    color: #fff;
    margin-left: auto;
    margin-right: auto
}
.services2 .title-box,
.services2 .services2-content,
.services2 .services2-img:before {
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}
.services2:hover .services2-content {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    margin-top: 1px
}
.services2:hover .title-box {
    opacity: 0;
    visibility: hidden;
    margin-bottom: 80px
}
.services2:hover .services2-img::before {
    height: 100%;
    visibility: visible
}
@media (max-width: 1199px) {
    .services2 .title-box {
        left: 20px;
        right: 20px;
        padding: 25px 0
    }
    .services2 .services2-text {
  
    }
}



/* ======= Services 3 ======= */
.services3 {
    text-align: left;
    margin: 0;
    margin-bottom: 30px;
    padding: 40px;
    padding-bottom: 0;
    background: #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    transition: .2s;
    overflow: hidden;
    cursor: pointer;
    z-index: 3
}
.services3:hover {
    background: #f4f4f4;
    z-index: 100;
    border-bottom: 1px solid #0982ac;

}
.services3-text-area p {
    transition: .25s;
    font-family: inherit !important;
    opacity: 0;
    color: #777;
    margin-bottom: -35px
}
.services3:hover .services3-text-area p {
    opacity: 1
}
.services3-img-area,
.services3-text-area {
    display: inline-block;
    vertical-align: bottom;
    transition: transform .2s ease-in-out
}
.services3-heading {
    font-family: 'Tajawal', sans-serif;
    font-size: 16px;
    color: #272727;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 300;
}
.services3-img-area {
    font-size: 42px;
    color: #0982ac;
    margin-bottom: 20px;
    margin-right: 0;
    float: left;
    width: 100%;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out
}
.services3:hover .services3-text-area,
.services3:hover .services3-img-area {
    -webkit-transform: translate3d(0, -67px, 0);
    transform: translate3d(0, -67px, 0)
}
.services3:hover .services3-img-area {
    opacity: 0
}
.services3 .services3-img-area img {
    margin-bottom: 5px;
    max-height: 40px;
    width: auto;
}



/* ======= Services 4 ======= */
.services4 {
    color: #777;
    position: -webkit-sticky;
}
.services4 .square-flip {
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -webkit-transform: perspective(1000px);
    -moz-transform: perspective(1000px);
    -ms-transform: perspective(1000px);
    transform: perspective(1000px);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
    background-size: cover;
    background-position: center center;
}
.services4 .square-flip {
    min-height: 420px;
    margin-bottom: 30px;
}
.services4 .square,
.services4 .square2 {
    width: 100%;
    height: 100%;
}
.services4 .square {
    background-size: cover;
    background-position: center center;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.services4 .square h4 {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #fff;
    font-size: 18px;
    text-align: left;
    margin-bottom: 0;
    display: inline-flex;
}
.services4 .square-flip .square {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 1;
}
.services4 .square-flip:hover .square {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.services4 .square2 {
    background: #f4f4f4;
    background-size: cover;
    background-position: center center;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.services4 .square2:before {
    top: 25px;
    left: 15px;
    right: 15px;
    bottom: 25px;
}
.services4 .square2 h4 {
    font-size: 20px;
    color: #272727;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 300;
}
.services4 .square2 p {
    font-size: 16px;
    color: #777;
}
.services4 .square2 .icon img {
    width: 60px;
    color: #777;
    margin-bottom: 20px;
}
.services4 .btn-line a {
    border-bottom: 1px solid #ccc;
    color: #777;
}
.services4 .btn-line a:hover {
    border-bottom: 1px solid #0982ac;
    color: #777;
}
.services4 .square-flip .square2 {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 1;
}
.services4 .square-flip:hover .square2 {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.services4 .square-container {
    padding: 40px;
    text-align: center;
    height: 420px;
    position: relative;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -webkit-transform: translateX(0px) scale(1);
    -ms-transform: translateX(0px) scale(1);
    transform: translateX(0px) scale(1);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 2;
}
.services4 .square-container .box-title {
    /*	width: 100%;*/
}
.text-vertical {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}
.services4 .square-flip:hover .square-container {
    -webkit-transform: translateX(-650px) scale(0.88);
    -ms-transform: translateX(-650px) scale(0.88);
    transform: translateX(-650px) scale(0.88);
}
.services4 .square-container2 {
    padding: 30px;
    text-align: left;
    position: relative;
    top: 50%;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -webkit-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
    transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 2;
}
.services4 .square-flip:hover .square-container2 {
    -webkit-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
    transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
}
.services4 .flip-overlay {
    display: block;
    background: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .2;
}
@media screen and (max-width: 991px) {
    .services4 .square-flip {
        margin-bottom: 30px;
    }
    .services4 {
        padding-bottom: 70px;
    }
}


/* services sidebar */

.sidebar .sidebar-widget {
    position: relative;
    display: block;
    margin-bottom: 30px;
}

.sidebar .sidebar-widget:last-child {
    margin-bottom: 0px;
}

.sidebar .sidebar-widget .widget-inner {
    position: relative;
    display: block;
    padding: 45px;
    background: #f4f4f4;
    border-radius: 0px;
}

.sidebar-title {
    position: relative;
    margin-bottom: 20px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px;
}

.sidebar-title h4 {
    position: relative;
    display: inline-block;
    font-family: 'Tajawal', sans-serif;
    font-size: 20px;
    line-height: 1.1em;
    color: #272727;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 0px;
}

.sidebar .services ul {
    position: relative;
}

.sidebar .services ul li {
    position: relative;
    line-height: 48px;
    font-size: 16px;
    color: #777;
    font-weight: 300;
    margin-bottom: 5px;
}

.sidebar .services ul li:before {
    content: "";
    position: absolute;
    left: -20px;
    top: 0;
    right: -20px;
    bottom: 0;
    background: #0982ac;
    border-radius: 0;
    opacity: 0;
    transition: all 0.3s ease;
}

.sidebar .services ul li:after {
    content: "";
    position: absolute;
    left: -20px;
    top: 50%;
    margin-top: -12px;
    height: 24px;
    border-left: 2px solid var(--thm-base);
    opacity: 0;
    transition: all 0.3s ease;
}

.sidebar .services ul li:last-child {
    margin-bottom: 0;
}

.sidebar .services ul li a {
    position: relative;
    display: block;
    font-size: 16px;
    color: #777;
    font-weight: 400;
    transition: all 0.3s ease;
    z-index: 1;
}

.sidebar .services ul li:hover a,
.sidebar .services ul li.active a {
    color: #fff;
}

.sidebar .services ul li:hover:before,
.sidebar .services ul li.active:before,
.sidebar .services ul li:hover:after,
.sidebar .services ul li.active:after {
    opacity: 1;
}

.sidebar .services ul li a:after {
    position: absolute;
    right: 0;
    top: -1px;
    font-family: "themify";
    content: "\e649";
    opacity: 1;
    font-size: 10px;
    font-weight: normal;
    z-index: 1;
}

.sidebar .services ul li a:hover:before,
.sidebar .services ul li.active a:before {
    opacity: 1;
    transform: scaleX(1);
    transition: all 0.3s ease 0.1s;
}


/* ======= Projects style ======= */

.projects .item {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.projects .item:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-filter: brightness(70%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.projects .item:hover .con {
    bottom: 0;
}

.projects .item img {
    -webkit-transition: all .5s;
    transition: all .5s;
}

.projects .item .con {
    padding: 30px 15px;
    position: absolute;
    bottom: -58px;
    left: 0;
    width: 100%;
    -webkit-transition: all .3s;
    transition: all .3s;
    text-align: center;
    z-index: 20;
    height: auto;
    box-sizing: border-box;
    background: -moz-linear-gradient(top, transparent 0, rgba(0, 0, 0, .1) 2%, rgba(0, 0, 0, .75) 90%);
    background: -webkit-linear-gradient(top, transparent 0, rgba(0, 0, 0, .01) 2%, rgba(0, 0, 0, .75) 90%);
    background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .01) 2%, rgba(0, 0, 0, .75) 90%);
}

.projects .item .con h6,
.projects .item .con h6 a {
    position: relative;
    color: #0982ac;
    font-size: 13px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 10px;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.projects .item .con h5,
.projects .item .con h5 a {
    position: relative;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 10px;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.projects .item .con p {
    color: #fff;
    font-size: 20px;
}

.projects .item .con i {
    color: #fff;
    font-size: 20px;
}

.projects .item .con i:hover {
    color: #0982ac;
}

.projects .item .con .butn-light {
    margin-left: 0;
    border-radius: 30px;
    border-color: #777;
    padding: 11px 30px;
}

.projects .item .con .butn-light:hover {
    border-color: #0982ac;
}


/* line animation */

.projects .item .line {
    text-align: center;
    height: 1px;
    width: 60px;
    background-color: rgba(255, 255, 255, 0.2);
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    margin: 0 auto 30px;
}

.projects .item:hover .line {
    width: 100%;
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: ease-in-out;
}


/* prev/next project */

.projects-prev-next {
    padding: 40px 0;
    border: none;
    border-top: 1px solid #f4f4f4;
    position: relative;
}

@media (max-width: 576px) {
    .projects-prev-next {
        text-align: center;
    }

    .projects-prev-next-left {
        margin-bottom: 10px;
    }
}

.projects-prev-next a {
    display: inline-block;
    font-size: 16px;
    color: #777;
    line-height: 1em;
}

.projects-prev-next a i {
    font-size: 20px;
}

.projects-prev-next a:hover {
    color: #0982ac;
}

.projects-prev-next .projects-prev-next-left a i {
    margin-right: 5px;
    font-size: 12px;
}

.projects-prev-next .projects-prev-next-right a i {
    font-size: 12px;
    margin-left: 0;
    margin-left: 5px;
}



/* ======= Projects2 style ======= */
/* project masonry style */
.project-masonry-wrapper-padding {}
.project-masonry-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;
}
.project-masonry-wrapper .project-masonry-item-img-link {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.project-masonry-wrapper .project-masonry-item-img-link:hover .project-masonry-item-img {
  -webkit-transform: translateY(-40px);
      -ms-transform: translateY(-40px);
          transform: translateY(-40px);
}
.project-masonry-wrapper .project-masonry-item-img-link:hover .project-masonry-item-img:after {
  opacity: 0.3;
}
.project-masonry-wrapper .project-masonry-item-img-link:hover .project-masonry-item-content {
  -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition-delay: .15s;
       -o-transition-delay: .15s;
          transition-delay: .15s;
}
.project-masonry-wrapper .project-masonry-item-img img {
  max-width: 100%;
	-webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
}
.project-masonry-item-img-link:hover img {
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
.project-masonry-wrapper .project-masonry-item-content {
  position: absolute;
  left: 0;
  bottom: -11px;
  max-width: 80%;
  background-color: #fff;
  padding-top: 25px;
  padding-right: 40px;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.5s linear;
  transition: -webkit-transform 0.5s linear;
  -o-transition: transform 0.5s linear;
  transition: transform 0.5s linear;
  transition: transform 0.5s linear, -webkit-transform 0.5s linear;
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
}
.project-masonry-wrapper .project-masonry-item-title {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 20px;
    color: #272727;
    font-weight: 300;
    letter-spacing: 5px;
    text-transform: uppercase;
}
.project-masonry-wrapper .project-masonry-item-category {
    font-size: 14px;
    line-height: 1.5em;
    color: #0982ac;
    font-weight: 300;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-family: 'Tajawal', sans-serif;
}
@media only screen and (max-width: 991px) {
  .project-masonry-wrapper .project-masonry-item-content {
    padding-top: 15px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .project-masonry-wrapper {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
  .project .items {
    padding: 12px;
	}
}
@media only screen and (max-width: 400px) {
  .project-masonry-wrapper {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* ======= Projects3 style ======= */
.bauen-project {}
.bauen-project-section {
    padding-top: 120px;
}
.bauen-project-page-section {
    padding-bottom: 80px;
}
.bauen-project-items .col-md-6:nth-child(2) {
    margin-top: 75px;
}
.bauen-project-filter {
    width: 100%;
    padding-bottom: 30px;
    padding-left: 0px;
    position: relative;
}
.bauen-project-filter li {
    margin-right: 15px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    color: #999;
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
}
.bauen-project-filter li:last-child {
    margin-right: 0;
}
.bauen-project-filter li.active {
    color: #0982ac;
    content: "";
    left: 0;
    bottom: -4px;
    border-bottom: 1px solid;
    -webkit-transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
    -o-transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
    transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
}
.bauen-project-filter li:hover {
    color: #0982ac;
}
.bauen-project-wrap {
    padding-bottom: 90px;
    padding-right: 0;
    padding-left: 90px;
    position: relative;
}
.bauen-project-wrap:hover img {
    -ms-transform: scale(0.95, 0.95);
    /* IE 9 */
    -webkit-transform: scale(0.95, 0.95);
    /* Safari */
    transform: scale(0.95, 0.95);
}
.bauen-project-wrap h3 {
    font-family: 'Tajawal', sans-serif;
    font-size: 20px;
    line-height: 1.25em;
    font-weight: 300;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #272727;
    margin-bottom: 0px;
}
.bauen-project-wrap p {
    color: #0982ac;
    font-family: 'Tajawal', sans-serif;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-bottom: 10px;
    align-items: center;
    display: inline-flex;
}
.bauen-project-content {
    position: absolute;
    left: 50px;
    bottom: 89px;
    background-color: #fff;
    padding: 20px 20px 20px 0;
    margin-right: 20px;
}
.bauen-project-link {
    color: #0982ac;
    font-size: 17px;
    font-weight: 400;
    position: relative;
    padding-right: 40px;
    line-height: 1.75em;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    padding-left: 80px;
}
.bauen-project-link:before {
    content: '';
    display: inline-block;
    width: 80px;
    height: 1px;
    background: #0982ac;
    position: absolute;
    left: 0;
    bottom: 15px;
    transition: all 0.3s ease-in-out;
}
.bauen-project-link:before:hover {
    padding-left: 5px;
    color: #0982ac;
    transition: all 0.3s ease-in-out;
}
.bauen-project-link i {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
}
.bauen-project-link:hover i {
    padding-left: 5px;
    color: #0982ac;
    transition: all 0.3s ease-in-out;
}
.bauen-project-link:hover {
    color: #0982ac;
}
@media (max-width: 991px) {
    .bauen-project-wrap {
        padding-left: 20px;
    }
    .bauen-project-wrap h3 {
        font-size: 22px;
    }
    .bauen-project-content {
        left: 0px;
        bottom: 0px;
    }
}
@media all and (max-width: 767px) {
    .bauen-project-items .col-md-6:nth-child(2) {
        margin-top: 0;
    }
}
@media all and (max-width: 575px) {
    .bauen-project-filter li {
        margin-right: 10px;
        font-size: 17px;
    }
    .bauen-project-filter li:last-child {
        margin-right: 0;
    }
    .bauen-project-items .single-item {
        padding-bottom: 30px;
    }
    .bauen-project-wrap {
        padding: 0;
    }
}

/* ======= Project4 style  ======= */
.projects4 {
    position: relative;
    margin-bottom: 90px;
}
.projects4:last-child {
    margin-bottom: 0px;
}
.projects4 figure {
    margin: 0;
    position: relative;
    width: 66.666667%;
    margin-left: auto;
}
.projects4.left figure {
    margin-left: 0;
    margin-right: auto;
}
.projects4 figure img:hover {
    transform: scale(0.95);
}
.projects4 .caption {
    background: #fff;
    position: absolute;
    left: 0;
    top: 50%;
    width: 50%;
    padding: 5% 4% 5% 0%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.projects4.left .caption {
    left: auto;
    right: 0;
    background: #fff;
    padding: 5% 0% 5% 4%;
}
.projects4 .caption h4 {
    font-size: 30px;
    color: #272727;
    letter-spacing: 5px;
    font-weight: 300;
    line-height: 1.25em;
    text-transform: uppercase;
}
.projects4 .caption h6 {
    color: #0982ac;
    font-family: 'Tajawal', sans-serif;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-bottom: 10px;
}
@media (max-width: 991.98px) {
    .projects4 figure {
        width: auto;
        margin: 0;
    }
    .projects4 .caption {
        position: relative;
        left: 0;
        top: 0;
        width: auto;
        padding: 30px;
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}


/* ======= Project Gallery style ======= */
.gallery-masonry-wrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;
}
.bauen-gallery-items .col-md-6:nth-child(2) {
    margin-top: 0px;
}
.bauen-gallery-filter {
    width: 100%;
    padding-bottom: 30px;
    padding-left: 0px;
    position: relative;
}
.bauen-gallery-filter li {
    margin-right: 15px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    color: #999;
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
}
.bauen-gallery-filter li:last-child {
    margin-right: 0;
}
.bauen-gallery-filter li.active {
    color: #0982ac;
    content: "";
    left: 0;
    bottom: -4px;
    border-bottom: 1px solid;
    -webkit-transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
    -o-transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
    transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
}
.bauen-gallery-filter li:hover {
    color: #0982ac;
}
.gallery-masonry-wrapper .gallery-masonry-item-img-link {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.gallery-masonry-wrapper .gallery-masonry-item-img-link:hover .gallery-masonry-item-img {
  -webkit-transform: translateY(-40px);
      -ms-transform: translateY(-40px);
          transform: translateY(-40px);
}
.gallery-masonry-wrapper .gallery-masonry-item-img-link:hover .gallery-masonry-item-img:after {
  opacity: 0.3;
}
.gallery-masonry-wrapper .gallery-masonry-item-img-link:hover .gallery-masonry-item-content {
  -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition-delay: .15s;
       -o-transition-delay: .15s;
          transition-delay: .15s;
}
.gallery-masonry-wrapper .gallery-masonry-item-content {
  position: absolute;
  left: 0;
  bottom: -11px;
  max-width: 80%;
  background-color: #fff;
  padding-top: 20px;
  padding-right: 40px;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.5s linear;
  transition: -webkit-transform 0.5s linear;
  -o-transition: transform 0.5s linear;
  transition: transform 0.5s linear;
  transition: transform 0.5s linear, -webkit-transform 0.5s linear;
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1), 
  -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
}
.gallery-masonry-wrapper .gallery-masonry-item-title {
  margin-top: 0;
    margin-bottom: 5px;
    font-size: 20px;
    color: #272727;
    font-weight: 300;
    letter-spacing: 5px;
    text-transform: uppercase;
}
.gallery-masonry-wrapper .gallery-masonry-item-category {
    font-size: 14px;
    line-height: 1.5em;
    color: #0982ac;
    font-weight: 300;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-family: 'Tajawal', sans-serif;
}
@media only screen and (max-width: 991px) {
  .gallery-masonry-wrapper .gallery-masonry-item-content {
    padding-top: 15px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .gallery-masonry-wrapper {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
    
     .bauen-gallery-items .col-md-6:nth-child(2) {
        margin-top: 0;
    }
}
@media all and (max-width: 575px) {
    .bauen-gallery-filter li {
        margin-right: 10px;
        font-size: 17px;
    }
    .bauen-gallery-filter li:last-child {
        margin-right: 0;
    }
    .bauen-gallery-items .single-item {
        padding-bottom: 30px;
    }
 
}
@media only screen and (max-width: 400px) {
  .gallery-masonry-wrapper {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}



/* ======= Project Page style ======= */
.project-page h6 {
    margin-bottom: 0px;
    position: relative;
    font-family: 'Tajawal', sans-serif;
    font-size: 16px;
    color: #777;
}
.project-bar {
    padding: 30px 45px 30px 45px;
    background-color: #f4f4f4;
    margin-top: -55px;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;
}
.project-bar h5  {
    color: #0982ac;
    font-size: 16px;
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
}
@media (max-width: 576px) {
    .project-bar {
     margin-top: 0px; 
    }
}
/* page list */
.page-list {
    position: relative;
    display: block;
}
.page-list li {
    position: relative;
    display: flex;
    align-items: center;
}
.page-list li+li {
    margin-top: 10px;
}
.page-list-icon {
    display: flex;
    align-items: center;
}
.page-list-icon span {
    font-size: 13px;
    color: #0982ac;
    background-color: transparent;
    border: 1px solid #ececec;
    border-radius: 50%;
    padding: 9px;
}
.page-list-text {
    margin-left: 15px;
}
.page-list-text p {
    font-size: 16px;
    margin: 0;
}


/* ======= Clients style ======= */

.clients {
    background-color: #f4f4f4;
}

.clients .owl-carousel {
    margin: 20px 0;
}

.clients .clients-logo {
    opacity: 1;
    line-height: 0;
}

.clients .clients-logo:hover {
    opacity: 1;
}

.clients img {
    -webkit-filter: none;
    filter: none;
}

.clients img:hover {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: all .5s;
    transition: all .5s;
}


/* ======= Gallery style ======= */

.gallery-item {
    position: relative;
    padding-top: 20px;
}

.gallery-box {
    overflow: hidden;
    position: relative;
}

.gallery-box .gallery-img {
    position: relative;
    overflow: hidden;
}

.gallery-box .gallery-img:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0px;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.gallery-box .gallery-img > img {
    transition: all 0.3s cubic-bezier(0.3, 0.1, 0.58, 1);
    border-radius: 0;
}

.gallery-box .gallery-detail {
    opacity: 0;
    color: #FFF;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    overflow: hidden;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.gallery-box .gallery-detail h4 {
    font-size: 18px;
}

.gallery-box .gallery-detail p {
    color: Rgba(0, 0, 0, 0.4);
    font-size: 14px;
}

.gallery-box .gallery-detail i {
    color: #fff;
    padding: 8px;
}

.gallery-box .gallery-detail i:hover {
    color: #fff;
}

.gallery-box:hover .gallery-detail {
    top: 50%;
    transform: translate(0, -50%);
    opacity: 1;
}

.gallery-box:hover .gallery-img:after {
    background: rgba(0, 0, 0, 0.4);
}

.gallery-box:hover .gallery-img > img {
    transform: scale(1.05);
}


/* magnific popup custom */

.mfp-figure button {
    border: 0px solid transparent;
}

button.mfp-close,
button.mfp-arrow {
    border: 0px solid transparent;
    background: transparent;
}


/* ======= Promo Video and Testimonials style ======= */


/* play button */
.vid-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vid-area h5 {
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 5px;
    margin-bottom: 30px;
    margin-left: 10px;
    text-transform: uppercase;
}

.play-button {
    position: relative;
    width: 85px;
    height: 85px;
    margin: auto;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    z-index: 4;
    margin-left: 15px;
}

.play-button svg {
    position: absolute;
    width: 100%;
    height: 100%;
}

.circle-fill circle {
    opacity: 1;
    fill: rgba(9, 130, 172, 0.5)
}

.polygon {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 90px;
    font-size: 35px;
    z-index: 2;
    padding-left: 5px;
}

.play-button svg.circle-fill {
    z-index: 1;
    stroke-dasharray: 322;
    stroke-dashoffset: 322;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.play-button:hover svg.circle-fill {
    stroke-dashoffset: 38.5;
}

.play-button svg.circle-track {
    z-index: 0;
}

.play-button.color {
    color: #0982ac;
}

.play-button.color .circle-fill circle {
    fill: #0982ac;
}


/* video gallery */
.video-gallery-button {
    position: relative;
    margin: auto;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    z-index: 4;
    margin-left: 15px;
    float: right;
}

.video-gallery-polygon {
    z-index: 2;
    padding-left: 5px;
    display: inline-block;
    position: absolute;
    bottom: 15px;
    right: 15px;
    background: transparent;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,0.5);
    color: #fff;
    padding: 7px 6px 7px 8px;
    line-height: 0;
}

.video-gallery-polygon:hover {
    background: #fff;
    border: 1px solid #fff;
    color: #0982ac;
}



/* testimonials style */

.testimonials-box {
    position: relative;
    background: #fff;
    padding: 60px 45px;
    margin-bottom: -90px;
}

.testimonials-box .head-box h6 {
    font-weight: 400;
    color: #777;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 10px;
}

.testimonials-box .head-box h4 {
    font-size: 20px;
    font-weight: 300;
    color: #272727;
    letter-spacing: 5px;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 15px;
    text-transform: uppercase;
}

.testimonials-box .head-box h4 span {
    color: #272727;
}

@media screen and (max-width: 991px) {
    .testimonials-box {
        margin-bottom: 90px;
        padding: 35px 20px;
    }
}

.testimonials .item {
    margin-bottom: 30px;
    position: relative;
}

.testimonials .item .quote {
    position: absolute;
    right: 15px;
    top: 60px;
    width: 100px;
    margin: 0 auto 30px;
    opacity: .05;
}

.testimonials .item p {
    color: #777;
}

.testimonials .item .info {
    text-align: left;
    margin: 30px 0 15px 0;
}

.testimonials .item .info .author-img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    float: left;
    overflow: hidden;
}

.testimonials .item .info .cont {
    margin-left: 70px;
}

.testimonials .item .info h6 {
        color: #0982ac;
    font-size: 16px;
    margin-bottom: 0px;
    padding-top: 7px;
    font-weight: 400;
    font-family: 'Tajawal', sans-serif;
}

.testimonials .item .info span {
    font-size: 16px;
    color: #777;
    margin-right: 5px;
    line-height: 1.25em;
}

.testimonials .item .info .rate {
    float: right;
}

.testimonials .item .info i {
    color: #0982ac;
    font-size: 10px;
}

.testimonials .item.bg {
    background: #f4f4f4;
}


/* ======= Blog 1 style ======= */

.bauen-blog .item {
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
}

.bauen-blog .item:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-filter: brightness(75%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.bauen-blog .item:hover .con {
    bottom: 0;
}

.bauen-blog .item img {
    -webkit-transition: all .5s;
    transition: all .5s;
    -webkit-filter: brightness(100%);
}

.bauen-blog .item .con {
    margin: -120px 20px 75px;
    padding: 15px 15px 25px 15px;
    background-color: #f4f4f4 !important;
    border: 1px solid #f4f4f4;
    border-radius: 0;
    overflow: hidden;
    -webkit-transition: .2s cubic-bezier(.3, .58, .55, 1);
    transition: .2s cubic-bezier(.3, .58, .55, 1);
    bottom: -55px;
    position: relative;
}

.bauen-blog .item .con .category {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: 1px;
}

.bauen-blog .item .con .category a {
    margin-right: 5px;
    position: relative;
}

.bauen-blog .item .con .category a:hover {
    color: #0982ac;
}

.bauen-blog .item .con .category a:last-child {
    margin-right: 0;
}

.bauen-blog .item .con .category a:last-child:after {
    display: none;
}

.bauen-blog .item .con h5,
.bauen-blog .item .con h5 a {
    color: #0982ac;
    font-size: 25px;
    font-family: 'Tajawal', sans-serif;
    font-weight: 300;
    margin-bottom: 0px;
    letter-spacing: 1px;
}

.bauen-blog .item .con h5:hover,
.bauen-blog .item .con h5 a:hover {
    color: #0982ac;
}

.bauen-blog .item .con p {
    color: #fff;
    font-size: 17px;
}

.bauen-blog .item .con i {
    color: #fff;
    font-size: 20px;
}



/* ======= Blog 2 style ======= */
.bauen-blog2 .img {
  position: relative;
    overflow: hidden;
}
.bauen-blog2 .img:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
.bauen-blog2 .img img {
    -webkit-transition: all .5s;
    transition: all .5s;
}
.bauen-blog2 .content {
  width: 100%;
  padding: 30px 45px;
}
.bauen-blog2 .content .date {
  display: table-cell;
  position: relative;
  width: 80px;
  z-index: 2;
}
.bauen-blog2 .content .date h3 {
    font-size: 65px;
    font-weight: 500;
    color: #0982ac;
    margin-bottom: 0px;
}
.bauen-blog2 .content .date h6 {
    color: #777;
    letter-spacing: 2px;
    font-size: 10px;
    font-family: 'Tajawal', sans-serif;
    text-transform: uppercase;
    font-weight: 300;
}
.bauen-blog2 .content .cont {
  display: table-cell;
  vertical-align: middle;
  padding-left: 15px;
}
.bauen-blog2 .content .cont h4 {
    margin: 15px 0;
    font-size: 20px;
    color: #000;
    letter-spacing: 5px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.5em;
}
.bauen-blog2 .content .cont .more {
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #0982ac;
  padding-left: 40px;
  position: relative;
}
.bauen-blog2 .content .cont .more:after {
  content: '';
  width: 30px;
  height: 1px;
  background: #0982ac;
  position: absolute;
  top: 50%;
  left: 0px;
}
.bauen-blog2 .content .cont .more:hover {
   color: #000; 
}
.bauen-blog2 .content .info h6 {
    font-size: 11px;
    letter-spacing: 2px;
    color: #777;
    font-family: 'Tajawal', sans-serif;
    text-transform: uppercase;
}
.bauen-blog2 .content .info .tags {
  color: #0982ac;
}
@media screen and (max-width: 991px) {
    .bauen-blog2 .content {
    padding: 0 30px;
    margin-top: 40px;
    }
    .bauen-blog2 .content .cont h4 {
        line-height: 1.25em;
    }
    .order2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
    .order1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }  
}
@media screen and (max-width: 480px) {
    .bauen-blog2 .content {
    padding: 0 15px;
  }
    .bauen-blog2 .content .date {
    width: 60px;
  }
    .bauen-blog2 .content .info .tags {
    margin: 5px 0 0;
  }
    .bauen-blog2 .content .date h3 {
    font-size: 40px;
  }
}

/* ======= Blog 3 style ======= */
.bauen-blog3 .item {
    margin-bottom: 30px;
}
.bauen-blog3 .post-img {
    position: relative;
    overflow: hidden;
}
.bauen-blog3 .post-img:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
.bauen-blog3 .post-img a {
    display: block;
}
.bauen-blog3 .post-img img {
    -webkit-transition: all .5s;
    transition: all .5s;
}
.bauen-blog3 .post-cont {
    padding: 30px 0;
}
.bauen-blog3 .post-cont .tag {
    display: block;
    font-weight: 400;
	font-size: 12px;
    letter-spacing: 2px;
    color: #0982ac;
    font-family: 'Tajawal', sans-serif;
    text-transform: uppercase;
	margin-bottom: 5px;
}
.bauen-blog3 .post-cont h5 {
    font-size: 25px;
    margin-bottom: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    text-transform: uppercase;
}
.bauen-blog3 .post-cont h5 a {
    color: #272727;
}
.bauen-blog3 .post-cont h5 a:hover {
    color: #0982ac;
}
.bauen-blog3 .post-cont .date {
    margin-top: 0px;
    font-size: 12px;
    text-align: right;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 5px;
}
.bauen-blog3 .post-cont .date a {
    color: #0982ac;
}
.bauen-blog3 .post-cont i {
    color: #ccc;
	margin: 0 10px;
	font-size: 12px;
}
/* blog sidebar */
.blog-sidebar .widget {
    background: #F4F4F4;
    padding: 30px;
    margin-bottom: 30px;
    overflow: hidden;
}
.blog-sidebar .widget ul {
    margin-bottom: 0;
    padding: 0;
}
.blog-sidebar .widget ul li {
    margin-bottom: 15px;
    color: #777;
    font-size: 16px;
    line-height: 1.5em;
}
.blog-sidebar .widget ul li a {
    color: #777;
}
.blog-sidebar .widget ul li a.active {
    color: #0982ac;
}
.blog-sidebar .widget ul li a:hover {
    color: #0982ac;
}
.blog-sidebar .widget ul li:last-child {
    margin-bottom: 0;
}
.blog-sidebar .widget ul li i {
    font-size: 9px;
    margin-right: 10px;
}
.blog-sidebar .widget .recent li {
    display: block;
    overflow: hidden;
}
.blog-sidebar .widget .recent .thum {
    width: 90px;
    overflow: hidden;
    float: left;
}
.blog-sidebar .widget .recent a {
    display: block;
    margin-left: 105px;
}
.blog-sidebar .widget-title {
    margin-bottom: 20px;
}
.blog-sidebar .widget-title h6 {
    padding-bottom: 5px;
    border-bottom: 1px solid #ececec;
    font-size: 20px;
    line-height: 1.75em;
    margin-bottom: 15px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #272727;
}
.blog-sidebar .search form {
    position: relative;
}
.blog-sidebar .search form input {
    width: 100%;
    padding: 10px;
    border: 0;
    background: #fff;
	margin-bottom: 0;
}
.blog-sidebar .search form button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    color: #000;
    border: 0;
    padding: 10px;
    cursor: pointer;
}
.blog-sidebar .tags li {
    margin: 3px !important;
    padding: 6px 16px;
    background-color: #0982ac;
    color: #fff !important;
    float: left;
}
.blog-sidebar ul.tags li a {
    font-size: 14px;
    color: #fff;
}
.blog-sidebar ul.tags li:hover,
.blog-sidebar ul.tags li a:hover {
    background-color: #272727;
    color: #fff;
}


/* pagination */

.bauen-pagination-wrap {
    padding: 0;
    margin: 0;
    text-align: center;
}

.bauen-pagination-wrap li {
    display: inline-block;
    margin: 0 5px;
}

.bauen-pagination-wrap li a {
    background: transparent;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    color: #777;
    font-weight: 300;
    border: 1px solid #efefef;
    font-family: 'Tajawal', sans-serif;
    font-size: 13px;
    border-radius: 100%;
}

.bauen-pagination-wrap li a i {
    font-size: 11px;
}

.bauen-pagination-wrap li a:hover {
    opacity: 1;
    text-decoration: none;
    background: transparent;
    border: 1px solid #0982ac;
    color: #0982ac;
}

.bauen-pagination-wrap li a.active {
    background-color: transparent;
    border: 1px solid #0982ac;
    color: #0982ac;
}

@media screen and (max-width: 768px) {
    .bauen-pagination-wrap {
        padding: 0;
        margin: 0;
        text-align: center;
    }
}




/* ======= Blog 4 style  ======= */
.bauen-blog4 {
    position: relative;
    margin-bottom: 90px;
}
.bauen-blog4:last-child {
    margin-bottom: 20px;
}
.bauen-blog4 figure {
    margin: 0;
    position: relative;
    width: 66.666667%;
    margin-left: auto;
}
.bauen-blog4.left figure {
    margin-left: 0;
    margin-right: auto;
}
.bauen-blog4 figure img:hover {
    transform: scale(0.95);
}
.bauen-blog4 .caption {
    background: #f4f4f4;
    position: absolute;
    left: 0;
    top: 50%;
    width: 50%;
    padding: 4%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.bauen-blog4.left .caption {
    left: auto;
    right: 0;
    background: #f4f4f4;
    padding: 4%;
}
.bauen-blog4 .caption h4,
.bauen-blog4 .caption h4 a {
    font-size: 27px;
    color: #272727;
    letter-spacing: 5px;
    font-weight: 300;
    text-transform: uppercase;
}
.bauen-blog4 .caption h6 {
    display: inline-block;
    padding: 8px 20px;
    background-color: #0982ac;
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 3px;
    text-transform: uppercase;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}
.bauen-blog4 .caption .info-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.bauen-blog4 .caption .info-wrapper .more {
    color: #777;
    text-decoration: none;
    font-size: 14px;
}
.bauen-blog4 .caption .info-wrapper .more i {
    color: #777;
    font-size: 10px;
}
.bauen-blog4 .caption .info-wrapper .date {
    color: #777;
    font-size: 14px;
}
@media (max-width: 991.98px) {
    .bauen-blog4 figure {
        width: auto;
        margin: 0;
    }
    .bauen-blog4 .caption {
        position: relative;
        left: 0;
        top: 0;
        width: auto;
        padding: 30px 20px;
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}
/* hr style */
hr {
    margin-top: 5px;
    margin-bottom: 20px;
    border: 0;
}
hr.border-1 {
    border-top: 1px solid #ececec;
}
hr.border-2 {
    border-top: 1px solid #ececec;
}
/* button link */
.link-btn {
    display: inline-block;
    position: relative;
    padding-bottom: 2px;
    margin-bottom: -2px;
    font-family: 'Tajawal', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 3px;
}
.link-btn i {
    margin-left: 3px;
    font-size: 12px;
    color: #0982ac;
}
.link-btn:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: #0982ac;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}
.link-btn:hover::before {
    width: 100%
}



/* ======= Post style ======= */

.bauen-post-section {
    padding-top: 120px;
}

.bauen-comment-section {
    padding-top: 60px;
    margin-bottom: 60px;
}

.bauen-comment-section h3 {
    font-size: 16px;
    color: #0982ac;
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
}

.bauen-comment-section h3 span {
    font-size: 12px;
    color: #777;
    font-weight: 400;
    margin-left: 15px;
}

.bauen-post-caption h1 {
    margin-bottom: 20px;
    font-size: 40px;
    text-transform: uppercase;
}

.bauen-post-comment-wrap {
    display: flex;
    margin-bottom: 60px;
}

.bauen-user-comment {
    margin-right: 30px;
    flex: 0 0 auto;
}

.bauen-user-comment img {
    border-radius: 100%;
    width: 100px;
}

.bauen-user-content {
    margin-right: 26px;
}

.bauen-repay {
    font-size: 14px;
    color: #0982ac;
    margin: 0;
    font-weight: 400;
}


/* ======= Contact style ======= */

#bauen-contactMap {
    width: 100%;
    height: 550px;
}


/* ======= Buttons style ======= */


/* dark */

.butn-dark {
    position: relative;
    line-height: 1.2em;
}

.butn-dark a {
    font-weight: 300;
    font-family: 'Tajawal', sans-serif;
    text-transform: uppercase;
    background: #0982ac;
    color: #fff;
    padding: 13px 30px;
    margin: 0;
    position: relative;
    font-size: 16px;
    letter-spacing: 3px;
}

.butn-dark a:after {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 100%;
    z-index: 1;
    background: #323232;
    color: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.butn-dark a span {
    position: relative;
    z-index: 2;
}

.butn-dark a:hover:after {
    width: 100%;
    left: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}

.butn-dark a:hover span {
    color: #fff;
}


/* light */

.butn-light {
    position: relative;
    line-height: 1.2em;
}

.butn-light a {
    font-weight: 300;
    font-family: 'Tajawal', sans-serif;
    text-transform: uppercase;
    background: transparent;
    color: #fff;
    padding: 12px 24px;
    margin: 0;
    position: relative;
    font-size: 16px;
    letter-spacing: 3px;
    border: 1px solid #fff;
}

.butn-light a:after {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 100%;
    z-index: 1;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #fff;
}

.butn-light a span {
    position: relative;
    z-index: 2;
}

.butn-light a:hover:after {
    width: 100%;
    left: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}

.butn-light a:hover span {
    color: #272727;
}


/* ======= Footer style ======= */

.main-footer {
    /* padding: 60px 0 0; */
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    position: initial;
}

.main-footer.dark {
    background: #fff;
}

.main-footer .abot p {
    color: #777;
}

.main-footer .abot .social-icon {
    text-align: center;
    margin-bottom: 20px;
}

.main-footer .abot .social-icon a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    border: 1px solid #efefef;
    color: #777;
    border-radius: 100%;
    margin-right: 3px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.main-footer .abot .social-icon a:hover {
    border: 1px solid #0982ac;
    background: transparent;
    color: #0982ac;
}

.main-footer .fothead h6 {
    margin-bottom: 0px;
    font-family: 'Tajawal', sans-serif;
    color: #0982ac;
    font-size: 17px;
}

.main-footer .fotcont .fothead h6 {
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
    position: relative;
    padding-bottom: 5px;
    font-size: 16px;
}

.main-footer .fotcont .fothead h6:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border: none;
}

.main-footer .fotcont p {
    margin-bottom: 0px;
    position: relative;
}


/* sub footer */

.main-footer .sub-footer {
    padding: 30px 0 30px 0;
    /* margin-top: 30px; */
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
}

.main-footer .sub-footer p {
    font-size: 14px;
    color: #777;
    margin-top: 7px;
}

.main-footer .sub-footer p a:hover {
    color: #0982ac;
}

.main-footer .sub-footer p.right {
    text-align: right;
}


/* ======= Corner Style ======= */

.hero-corner {
    top: 50px;
    right: 50px;
    z-index: 21;
    border-top: 1px solid;
    border-right: 1px solid;
}

.hero-corner2 {
    top: 50px;
    left: 50px;
    border-top: 1px solid;
    border-left: 1px solid;
}

.hero-corner3 {
    bottom: 50px;
    right: 50px;
    border-bottom: 1px solid;
    border-right: 1px solid;
}

.hero-corner4 {
    bottom: 50px;
    left: 50px;
    border-bottom: 1px solid;
    border-left: 1px solid;
}

.hero-corner,
.hero-corner2,
.hero-corner3,
.hero-corner4 {
    position: absolute;
    width: 70px;
    height: 70px;
    z-index: 21;
    border-color: rgba(255, 255, 255, 0.2);
}


/* ======= Preloader Style ======= */
.preloader-bg,
#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    z-index: 999999;
}
#preloader {
    display: table;
    table-layout: fixed;

}
#preloader-status {
    display: table-cell;
    vertical-align: middle;
}
.preloader-position {
    position: relative;
    margin: 0 auto;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}
.loader {
    position: relative;
    width: 55px;
    height: 55px;
    left: 50%;
    top: auto;
    margin-left: -22px;
    margin-top: 2px;
    -webkit-animation: rotate 1s infinite linear;
    -moz-animation: rotate 1s infinite linear;
    -ms-animation: rotate 1s infinite linear;
    -o-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;
    border: 1px solid rgba(0,0,0,0.1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.loader span {
    position: absolute;
    width: 55px;
    height: 55px;
    top: -1px;
    left: -1px;
    border: 1px solid transparent;
    border-top: 1px solid #0982ac;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* ======= toTop Button style ======= */

.progress-wrap {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear;
}

.progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.progress-wrap::after {
    position: absolute;
    font-family: 'themify';
    content: '\e648';
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    font-weight: 700;
    color: #323232;
    left: 0;
    top: 0;
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear;
}

.progress-wrap svg path {
    fill: none;
}

.progress-wrap svg.progress-circle path {
    stroke: rgba(0, 0, 0, 0.2);
    stroke-width: 2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear;
}

.progress-wrap {
    -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}

.progress-wrap::after {
    color: #0982ac;
}

.progress-wrap svg.progress-circle path {
    stroke: #0982ac;
}


/* ======= Accordion Box (for Faqs) style ======= */
.accordion-box {
  position: relative;
}
.accordion-box .block {
  position: relative;
  background: #f4f4f4;
  border-radius: 0px;
  overflow: hidden;
  margin-bottom: 16px;
}
.accordion-box .block.active-block {
  background-color: #f4f4f4;
}
.accordion-box .block:last-child {
  margin-bottom: 0;
}
.accordion-box .block .acc-btn {
    position: relative;
    font-size: 20px;
    line-height: 27px;
    font-weight: 300;
    letter-spacing: 1px;
    cursor: pointer;
    padding: 20px 40px;
    padding-right: 60px;
    color: #777;
    transition: all 500ms ease;
    font-family: 'Tajawal', sans-serif;
}
.accordion-box .block .acc-btn .count {
  color: #0982ac;
  padding-right: 3px;
}
.accordion-box .block .acc-btn:before {
  position: absolute;
    right: 35px;
    top: 20px;
    height: 30px;
    font-size: 14px;
    font-weight: normal;
    color: #0982ac;
    line-height: 30px;
    content: "\e61a";
    font-family: "Themify";
    transition: all 500ms ease;
}
.accordion-box .block .acc-btn.active {
    background-color: #f4f4f4;
    color: #0982ac;
}
.accordion-box .block .acc-btn.active:before {
  color: "Themify";
  content: "\e622";
}
.accordion-box .block .acc-content {
  position: relative;
  display: none;
}
.accordion-box .block .content {
  position: relative;
    padding: 0px 40px 30px;
    background-color: #f4f4f4;
    color: #777;
}
.accordion-box .block .acc-content.current {
  display: block;
}
.accordion-box .block .content .text {
  display: block;
  position: relative;
  top: 0px;
  display: block;
}


/* ======= Pricing plan style ======= */
.pricing-card {
    background-color: #f4f4f4;
     padding: 60px 40px 40px 40px;
    border-radius: 0;
    margin-bottom: 30px;
}
.pricing-card-bottom {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pricing-card-list {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}
.pricing-card-list li {
  position: relative;
    font-size: 16px;
    padding-left: 30px;
}
.pricing-card-list li+li {
  margin-top: 5px;
}
.pricing-card-list li i {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(50%);
    font-size: 15px;
    color: #0982ac;
}
.pricing-card-list li i.unavailable {
  color: #777;
}
.pricing-card:hover {
  opacity: 1;
}
.pricing-card:hover i {
  color: #0982ac;
}
.pricing-card-name {
      line-height: 1;
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Tajawal', sans-serif;
    color: #0982ac;
    margin-bottom: 20px;
    letter-spacing: 5px;
    font-weight: 300;
}
.pricing-card-amount {
  margin: 0;
  line-height: 1;
  font-size: 40px;
  margin-bottom: 20px;
 color: #272727;
}
/* butn */
.butn-pricing {
    position: relative;
    line-height: 1.2em;
}
.butn-pricing a {
    font-weight: 300;
    font-family: 'Tajawal', sans-serif;
    text-transform: uppercase;
    background: #0982ac;
    color: #fff;
    padding: 13px 30px;
    margin: 0;
    position: relative;
    font-size: 16px;
    letter-spacing: 3px;
}
.butn-pricing a:after {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 100%;
    z-index: 1;
    background: #272727;
    color: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.butn-pricing a span {
    position: relative;
    z-index: 2;
}
.butn-pricing a:hover:after {
    width: 100%;
    left: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}
.butn-pricing a:hover span {
    color: #fff;
}



/* ======= Error Page 404 style ======= */
.error-form {
  position: relative;
  max-width: 520px;
  margin: 50px auto 0;
}
.error-form form {
  position: relative;
}
.error-form .form-group {
  position: relative;
  margin: 0;
}
.error-form .form-group input[type="text"],
.error-form .form-group input[type="search"],
.error-form .form-group input[type="email"] {
  position: relative;
  width: 100%;
  line-height: 30px;
  padding: 20px 60px 20px 30px;
  height: 70px;
  display: block;
 font-family: 'Tajawal', sans-serif;
  font-size: 16px;
  background: #f4f4f4;
  color: #777;
  border-radius: 7px;
  border: 1px solid #f4f4f4;
  transition: all 300ms ease;
}
.error-form .form-group input[type="text"]:focus,
.error-form .form-group input[type="email"]:focus,
.error-form .form-group input[type="search"]:focus {
  border-color: #f4f4f4;
}
.error-form .form-group input::-webkit-input-placeholder {
  color: #777;
}
.error-form .form-group .theme-btn {
      position: absolute;
    right: 20px;
    top: 10px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    background: none;
    color: #777;
}
.error-form .form-group .theme-btn span {
  vertical-align: middle;
    outline: none;
}


/* ======= Process style ======= */
.process .item {
  position: relative;
  z-index: 4;
}
.process .item img {
  position: absolute;
  width: 70%;
  right: -40%;
  top: 15px;
  opacity: .5;
}
.process .item img.tobotm {
  -webkit-transform: rotateX(180deg);
   transform: rotateX(180deg);
}
.process .item.odd {
  margin-top: 50px;
}
.process .item .cont {
  padding: 40px 20px;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  position: relative;
  z-index: 3;
  -webkit-transition: all .3s;
  transition: all .3s;
  text-align: left;
}
.process .item .cont:hover {
  background-color: #0982ac;
  border: 1px solid #0982ac;
}
.process .item .cont:hover h3 {
    -webkit-text-stroke: 1px rgba(255,255,255, 0.5);
}
.process .item .cont:hover h6 {
  color: #fff;
}
.process .item .cont:hover p {
  color: #fff;
}
.process .item .icon {
    color: #0982ac;
    font-size: 30px;
    width: 80px;
    height: 80px;
    line-height: 85px;
    border-radius: 50%;
    border: 1px dashed #e8e4e4;
    margin-bottom: 30px;
}
.process .item h3 {
    position: absolute;
    bottom: 0px;
    text-align: right;
    width: 100%;
    font-size: 40px;
    font-weight: 600;
    z-index: -1;
    color: transparent;
    -webkit-text-stroke: 1px rgba(0,0,0, 0.1);
    right: 20px;
}
.process .item h6 {
    font-family: 'Tajawal', sans-serif;
    color: #272727;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 300;
}
@media screen and (max-width: 991px) {
 .process .item img {
    display: none;
  }
 .mb-md50 {
    margin-bottom: 50px;
}
}
/* line animation */
.process .item .line {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0px;
}
.process .item .line:after {
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 50px;
    height: 1px;
    background-color: rgba(0,0,0, 0.075);
    content: '';
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}
.process .item:hover .line:after {
    width: 100%;
    background-color: rgba(255,255,255,0.75);
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: ease-in-out;
}



/* ======= Process 2 style ======= */
.process2.item{
    background-color: #f4f4f4;
    padding: 30px 20px;
    position: relative;
    text-align: left;
}
.process2.item .icon {
    margin-bottom: 15px;
    font-size: 40px; 
    color: #0982ac;
}
.process2.item i.number {
    position: absolute;
    top: 110%;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #ececec;
    line-height: 39px;
    text-align: center;
    left: 22px;
    font-style: normal;
    font-family: 'Tajawal', sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    color: #777;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.process2.item h3 {
    font-family: 'Tajawal', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #272727;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 5px;
}
.row > [class*='col-']:nth-child(even) .process2.item { margin-top: 65px; }
.row > [class*='col-']:nth-child(even) .process2.item i.number { top: -60px;}
.row > [class*='col-']:nth-of-type(odd) .process2.item:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-top-color: #f4f4f4;
    border-width: 16px;
    left: 25px;
}
.row > [class*='col-']:nth-of-type(even) .process2.item:before {
    top: -30px;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: #f4f4f4;
    border-width: 16px;
    left: 25px;
}
.process2.item:hover i.number {
    background-color: transparent;
    border: 1px solid #0982ac;
}
/* line animation */
.process2.item .line {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0px;
}
.process2.item .line:after {
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 50px;
    height: 1px;
    background-color: rgba(0,0,0, 0.075);
    content: '';
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}
.process2.item:hover .line:after {
    width: 100%;
    background-color: rgba(0,0,0, 0.075);
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: ease-in-out;
}


/* ====== Coming Soon ======= */
.comming{
	min-height: 100vh;
	height: auto;
	position: relative;
	background-blend-mode: overlay;
	background: rgba(0,0,0,.6);
	background-image: url("/public/img/slider/1.jpg");
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	color: #fff;
}
@media (max-width: 767px){
	.comming{
		min-height: 200vh;
	}
}
.comming .item {
    background-color: rgba(0,0,0, 0.3);
    border: 1px solid rgba(255,255,255,0.1);
    padding: 30px 15px;
    margin-bottom: 30px;
}
.comming h6 {
    color: #0982ac;
    font-size: 13px;
    font-family: 'Tajawal', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 5px;
	line-height: 1.5em;
}
.comming h3 {
    font-size: 48px;
    line-height: 1.25em;
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
    color: #fff;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 15px;
}
.comming h2 {
    font-size: 48px;
    line-height: 1.25em;
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
    color: #fff;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 15px;
    margin-bottom: 20px;
	margin-top: 20px;
}
.comming p {
	color: #fff;
	font-size: 16px;
}
/* coming form */
.comming form {
  margin-top: 30px;
  position: relative;
}
.comming form input {
    width: 100%;
    padding: 11px 50px 11px 11px;
    background-color: transparent;
    border: 1px solid #0982ac;
    border-radius: 0px;
    color: #fff;
    margin-bottom: 15px;
	outline: none;
}
.comming form button {
    background-color: #0982ac;
    padding: 8px 20px;
    border-radius: 0px;
    color: #fff;
    border: 0;
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-weight: 300;
    outline: none;
}
.comming form button:hover {
  background-color: #323232;
  color: #fff;
	outline: none;
}
/* go home */
.go-back{
	margin-top: 45px;
}
.go-back a {
	color: #fff;
	display: inline-block;
	font-size: 13px;
    font-family: 'Tajawal', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
	letter-spacing: 5px;
}
.go-back a:hover {
	color: #0982ac;
}
.go-back a span{
	transition: all .5s;
}
.go-back a span i {
	color: #fff;
	font-size: 14px;
}
.go-back a:hover span{
	padding-right: 15px;
}


/* =======  Overlay Effect Bg image style  ======= */

[data-overlay-dark],
[data-overlay-darkgray],
[data-overlay-light] {
  position: relative;
}

[data-overlay-dark] .container,
[data-overlay-darkgray] .container,
[data-overlay-light] .container {
  position: relative;
  z-index: 2;
}

[data-overlay-dark]:before,
[data-overlay-darkgray]:before,
[data-overlay-light]:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

[data-overlay-dark]:before {
  background: #000;
}
[data-overlay-darkgray]:before {
  background: #fff;
}
[data-overlay-light]:before {
  background: #fff;
}

[data-overlay-dark] h1,
[data-overlay-dark] h2,
[data-overlay-dark] h3,
[data-overlay-dark] h4,
[data-overlay-dark] h5,
[data-overlay-dark] h6,
[data-overlay-dark] span {
  color: #fff;
}

[data-overlay-darkgray] h1,
[data-overlay-darkgray] h2,
[data-overlay-darkgray] h3,
[data-overlay-darkgray] h4,
[data-overlay-darkgray] h5,
[data-overlay-darkgray] h6,
[data-overlay-darkgray] span {
  color: #fff;
}

[data-overlay-light] h1,
[data-overlay-light] h2,
[data-overlay-light] h3,
[data-overlay-light] h4,
[data-overlay-light] h5,
[data-overlay-light] h6,
[data-overlay-light] span {
  color: #777;
}

[data-overlay-dark] p {
  color: #777;
}
[data-overlay-darkgray] p {
  color: #777;
}
[data-overlay-light] p {
  color: #777;
}

[data-overlay-dark="0"]:before,
[data-overlay-darkgray="0"]:before,
[data-overlay-light="0"]:before {
  opacity: 0;
}

[data-overlay-dark="1"]:before,
[data-overlay-darkgray="1"]:before,
[data-overlay-light="1"]:before {
  opacity: 0.1;
}

[data-overlay-dark="2"]:before,
[data-overlay-darkgray="2"]:before,
[data-overlay-light="2"]:before {
  opacity: 0.2;
}

[data-overlay-dark="3"]:before,
[data-overlay-darkgray="3"]:before,
[data-overlay-light="3"]:before {
  opacity: 0.3;
}

[data-overlay-dark="4"]:before,
[data-overlay-darkgray="4"]:before,
[data-overlay-light="4"]:before {
  opacity: 0.4;
}

[data-overlay-dark="5"]:before,
[data-overlay-darkgray="5"]:before,
[data-overlay-light="5"]:before {
  opacity: 0.5;
}

[data-overlay-dark="6"]:before,
[data-overlay-darkgray="6"]:before,
[data-overlay-light="6"]:before {
  opacity: 0.6;
}

[data-overlay-dark="7"]:before,
[data-overlay-darkgray="7"]:before,
[data-overlay-light="7"]:before {
  opacity: 0.7;
}

[data-overlay-dark="8"]:before,
[data-overlay-darkgray="8"]:before,
[data-overlay-light="8"]:before {
  opacity: 0.8;
}

[data-overlay-dark="9"]:before,
[data-overlay-darkgray="9"]:before,
[data-overlay-light="9"]:before {
  opacity: 0.9;
}

[data-overlay-dark="10"]:before,
[data-overlay-darkgray="10"]:before,
[data-overlay-light="10"]:before {
  opacity: 1;
}


/* ======= Responsive style ======= */
@media (min-width: 1200px) {
    .container {
        max-width: 1140px !important;
    }
}


@media screen and (max-width: 991px) {
    .bg-fixed {
        background-attachment: scroll !important;
    }

    .header {
        background-attachment: scroll !important;
        background-position: 50% 0% !important;
    }

    .hero-corner,
    .hero-corner2,
    .hero-corner3,
    .hero-corner4 {
        display: none;
    }

    .header .caption h1 {
        font-size: 40px;
    }
}

@media screen and (max-width: 767px) {
    .header .caption .o-hidden {
        padding-right: 0px;
    }

    .hero .intro h3 {
        font-size: 28px;
    }

    .hero .intro h4 {
        font-size: 20px;
    }

    .header .caption h1 {
        font-size: 30px;
        margin-bottom: 15px;
        letter-spacing: 10px;
        line-height: 1.35em;
    }

    .header .caption p {
        font-size: 15px;
        margin-right: 10px;
    }

    .header .caption .butn-light {
        margin-right: 10px;
    }

    .portfolio .filtering span {
        margin-bottom: 10px;
    }

    .bauen-blog .item .con h5,
    .bauen-blog .item .con h5 a {
        font-size: 20px;
    }

    .bauen-comment-section h3 span {
        margin-left: 0px;
    }

    .main-footer .sub-footer p.right {
        text-align: left;
    }

    .main-footer .abot .social-icon {
        text-align: left;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 480px) {
    .section-head h4 {
        font-size: 30px;
    }

    .footer {
        padding: 60px 0;
    }

    .text-left {
        text-align: center;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: center;
    }
}
/* addition */
.selectI18n {
    background: none;
    border: none;
    font-family: 'Tajawal', sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.25em;
    color: #777;
    margin: 13px 0 0 0;
    padding: 0;
    list-style: none;
}
/* services box */
.blogBox {
    margin: 0px 0px 25px;
  }
  
  .blogBox:hover .blogImage img {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-filter: brightness(1);
            filter: brightness(1);
    border-radius: 3px;
  }
  
  .blogBox:hover .blogInfo {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  .blogImage {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 3px;
  }
  
  .blogImage img {
    max-width: 100%;
    border-radius: 3px;
    background-size: cover;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 100%;
  }
  
  .blogInfo {
    padding: 30px 40px;
    background: #FFFFFF;
    margin: 10px 0px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.09);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .blogInfoTitle h6 {
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 20px;
    line-height: 2;
    letter-spacing: .5px;
  }
  
  .blogInfoTitle p {
    letter-spacing: 1px;
    font-size: 14px;
    opacity: 0.6;
  }
  
  .blogInfoP p span {
    font-weight: 500;
    color: #F0A12B;
  }
  
  .blogInfoTitle h3 {
    font-size: 20px;
    margin: 5px 0px 5px;
  }
  
  .blogInfoP2 p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    margin: 10px 0px 0px;
  }
  
  .blogInfoP2 p a {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-weight: 500;
  }
  
  /* slider */

/*-- Theme Slider --*/
.theme_slider {
    position: relative;
    padding: calc(2.3125rem + 10.92857vw) 0 0 0;
  }
  
  @media (min-width: 1400px) {
    .theme_slider {
      padding: 11.875rem 0 0 0;
    }
  }
  
  .theme_slider .swiper {
    padding-bottom: calc(1.5rem + 2.57143vw);
  }
  
  @media (min-width: 1400px) {
    .theme_slider .swiper {
      padding-bottom: 3.75rem;
    }
  }
  
  .theme_slider .swiper-slide {
    padding-right: calc(2.0938rem + 8.67909vw);
  }
  
  @media (min-width: 1400px) {
    .theme_slider .swiper-slide {
      padding-right: 9.688rem;
    }
  }
  
  .theme_slider .swiper-slide .slider {
    background-repeat: repeat;
    background-size: cover;
    background-position: left center;
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 100px 0px;
  }
  
  .theme_slider .swiper-slide .slider .slide_content {
    max-width: 310px;
    margin-right: calc(-4.375rem - 6.42857vw);
    transform-style: preserve-3d;
  }
  
  @media (min-width: 1400px) {
    .theme_slider .swiper-slide .slider .slide_content {
      margin-right: -7.5rem;
    }
  }
  
  .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper {
    transform: scale(0.8);
    transition: all 0.5s ease-in-out;
  }
  
  .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .meta .category {
    font-size: 0.6875rem;
    font-weight: 600;
  }
  
  .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    position: relative;
    padding-top: calc(1.75rem + 5.14286vw);
    margin-bottom: calc(1.75rem + 5.14286vw);
  }
  
  @media (min-width: 1400px) {
    .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
      padding-top: 6.25rem;
    }
  }
  
  @media (min-width: 1400px) {
    .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
      margin-bottom: 6.25rem;
    }
  }
  
  .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: calc(1.75rem + 5.14286vw);
    background-color: #54595f;
  }
  
  @media (min-width: 1400px) {
    .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
      height: 6.25rem;
    }
  }
  
  .theme_slider .swiper-slide-active .slider .slide_content .slide_content_wrapper {
    transform: scale(1);
    transition-delay: 0.5s;
  }

  .theme_slider_3 {
    position: relative;
  }
  
  .theme_slider_3 .swiper-slide .slider {
    background-repeat: repeat;
    background-size: cover;
    background-position: left center;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: calc(3rem + 18vw);
    padding-bottom: calc(3.625rem + 24.42857vw);
  }
  
  @media (min-width: 1400px) {
    .theme_slider_3 .swiper-slide .slider {
      padding-top: 18.75rem;
    }
  }
  
  @media (min-width: 1400px) {
    .theme_slider_3 .swiper-slide .slider {
      padding-bottom: 25rem;
    }
  }
  
  .theme_slider_3 .swiper-slide .slider::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .theme_slider_3 .swiper-slide .slider .slide_content {
    max-width: 70%;
    transition: all 0.5s ease-in-out;
  }
  
  .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper {
    display: flex;
    align-items: center;
    padding: 0 0;
    transition: all 0.5s ease-in-out;
  }
  
  .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner {
    text-align: left;
  }
  
  .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .meta {
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1875rem;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: all 0.5s ease-in-out;
  }
  
  .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    position: relative;
    font-family: "saol_displayregular", sans-serif;
    font-size: calc(1.78125rem + 5.46429vw);
    line-height: calc(1.7rem + 4.62857vw);
    font-weight: 400;
    margin-bottom: calc(1.28125rem + 0.32143vw);
    opacity: 0;
    visibility: hidden;
    transform: skew(50deg, 0deg);
    transition: all 0.5s ease-in-out;
  }
  
  @media (min-width: 1400px) {
    .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
      font-size: 6.5625rem;
    }
  }
  
  @media (min-width: 1400px) {
    .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
      line-height: 5.75rem;
    }
  }
  
  @media (min-width: 1400px) {
    .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
      margin-bottom: 1.5625rem;
    }
  }
  
  .theme_slider_3 .swiper-slide .slider .slide_content .details_link {
    font-size: calc(1.275rem + 0.25714vw);
    line-height: calc(1.325rem + 0.77143vw);
    font-weight: 400;
    text-transform: capitalize;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: all 0.5s ease-in-out;
  }
  
  @media (min-width: 1400px) {
    .theme_slider_3 .swiper-slide .slider .slide_content .details_link {
      font-size: 1.5rem;
    }
  }
  
  @media (min-width: 1400px) {
    .theme_slider_3 .swiper-slide .slider .slide_content .details_link {
      line-height: 2rem;
    }
  }
  
  .theme_slider_3 .swiper-slide .slider .slide_content .details_link a {
    color: #ffffff;
  }
  
  .theme_slider_3 .swiper-slide .slider .slide_content .details_link a .link_text {
    cursor: auto;
  }
  
  .theme_slider_3 .swiper-slide .slider .slide_content .details_link a .link_icon {
    color: var(--main-o-color);
  }
  
  .theme_slider_3 .swiper-slide:nth-child(odd) .slider {
    justify-content: end;
  }
  
  .theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .meta {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: 1.5s;
  }
  
  .theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    opacity: 1;
    visibility: visible;
    transform: skew(0deg, 0deg);
    transition-delay: 1s;
  }
  
  .theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
    transform: scale(1);
  }
  
  .theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .details_link {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: 1.5s;
  }
  
  .theme_slider_3 .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .theme_slider_3 .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    width: 16px;
  }
  
  .theme_slider_3 .swiper-button-prev, .theme_slider_3 .swiper-rtl .swiper-button-next {
    left: calc(1.5625rem + 3.21429vw);
  }
  
  @media (min-width: 1400px) {
    .theme_slider_3 .swiper-button-prev, .theme_slider_3 .swiper-rtl .swiper-button-next {
      left: 4.375rem;
    }
  }
  
  .theme_slider_3 .swiper-button-next, .theme_slider_3 .swiper-rtl .swiper-button-prev {
    right: calc(1.5625rem + 3.21429vw);
  }
  
  @media (min-width: 1400px) {
    .theme_slider_3 .swiper-button-next, .theme_slider_3 .swiper-rtl .swiper-button-prev {
      right: 4.375rem;
    }
  }
  
  .theme_slider_3 .swiper-button-next:after, .theme_slider_3 .swiper-button-prev:after {
    content: none;
  }
  
  .theme_slider_3 .details_link.swiper-button-prev a .link_icon .line, .theme_slider_3 .details_link.swiper-button-next a .link_icon .line {
    width: 5.625rem;
  }
  
  .theme_slider_3 .details_link.swiper-button-prev a .link_icon .circle, .theme_slider_3 .details_link.swiper-button-next a .link_icon .circle {
    width: 3.75rem;
    height: 3.75rem;
  }
  
  .theme_slider_3 .details_link.l-dir a .link_icon .dot {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 18px solid var(--main-o-color);
  }
  
  .theme_slider_3 .details_link.r-dir a .link_icon .dot {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 18px solid var(--main-o-color);
  }
  
  
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: -10px;
  }
  
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0rem 0.9375rem;
  }
  
  .swiper-pagination-bullet {
    width: 0.6875rem;
    height: 0.6875rem;
    background-color: #999999;
    opacity: 1;
  }
  
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--main-o-color);
  }
  
  .swiper-navigation {
    --button-width: 44px;
    --button-height: 44px;
    position: absolute;
    right: 0px;
    bottom: 0;
    width: var(--button-width);
    height: calc(var(--button-height) * 2);
    overflow: hidden;
    z-index: 9;
    transition: all 1s ease-in-out;
  }
  /* top of naviga */
  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset,60%); /* Adjust the top position as needed */
  }
  
  .swiper-navigation .swiper-button-next, .swiper-navigation .swiper-button-prev {
    font-size: 1.25rem;
    color: #000000;
    background: #ffffff;
    position: absolute;
    top: 100%;
    width: var(--button-width);
    height: var(--button-height);
    margin-top: 0px;
    transition: all 0.5s ease-in-out;
  }
  
  .swiper-navigation .swiper-button-next:hover, .swiper-navigation .swiper-button-prev:hover {
    background: var(--main-o-color);
    color: #ffffff;
  }
  
  .swiper-navigation .swiper-button-prev, .swiper-navigation .swiper-rtl .swiper-button-next {
    left: auto;
    right: 0;
  }
  
  .swiper-navigation .swiper-button-next, .swiper-navigation .swiper-rtl .swiper-button-prev {
    right: 0;
    left: auto;
    margin-top: 1px;
  }
  
  .swiper-navigation .swiper-button-next.swiper-button-disabled, .swiper-navigation .swiper-button-prev.swiper-button-disabled {
    opacity: 1;
    background: rgba(255, 255, 255, 0.65);
  }
  
  .swiper-navigation .swiper-button-next:after, .swiper-navigation .swiper-button-prev:after {
    font-size: 20px;
    display: none;
  }
  
  .swiper:hover .swiper-navigation .swiper-button-prev {
    top: 0;
  }
  
  .swiper:hover .swiper-navigation .swiper-button-next {
    top: var(--button-height);
  }
  
  .swiper_gallery_2 .swiper-button-next:after, .swiper_gallery_2 .swiper-button-prev:after {
    content: none;
  }
  
  .mt-lg-minus {
    margin-top: calc(-4.375rem - 9.64286vw);
    z-index: 1;
  }
  
  @media (min-width: 1400px) {
    .mt-lg-minus {
      margin-top: -10.625rem;
    }
  }
  
  .mt-lg-minus .gutter-90:nth-child(1) .grid-item, .mt-lg-minus .gutter-90:nth-child(2) .grid-item {
    padding-top: 0;
  }
  
  .rotate-logo {
    position: absolute;
    left: 50%;
    bottom: 88px;
    display: inline-block;
    z-index: 2;
  }
  
  .rotate-logo .rotate-bg {
    display: inline-block;
  }
  
  .rotate-logo .rotate-text {
    position: absolute;
    left: 8px;
    top: 8px;
    transform-origin: center;
    animation: rotateLogo 20s linear infinite;
  }
  
  @keyframes rotateLogo {
    100% {
      transform: rotate(360deg);
    }
  }
  
  .funfacts_inner {
    display: flex;
    justify-content: space-around;
    text-align: center;
    flex-flow: row wrap;
  }
  
  .funfacts_inner .funfact .funfact_content h2, .funfacts_inner .funfact .funfact_content .h2 {
    color: #ffffff;
    font-size: calc(1.525rem + 2.82857vw);
    font-weight: 600;
    line-height: calc(1.525rem + 2.82857vw);
    margin-bottom: 0;
  }
  
  @media (min-width: 1400px) {
    .funfacts_inner .funfact .funfact_content h2, .funfacts_inner .funfact .funfact_content .h2 {
      font-size: 4rem;
    }
  }
  
  @media (min-width: 1400px) {
    .funfacts_inner .funfact .funfact_content h2, .funfacts_inner .funfact .funfact_content .h2 {
      line-height: 4rem;
    }
  }
  
  .funfacts_inner .funfact .funfact_content span {
    color: #ffffff;
    font-size: calc(1.525rem + 2.82857vw);
    font-weight: 600;
    line-height: calc(1.525rem + 2.82857vw);
  }
  
  @media (min-width: 1400px) {
    .funfacts_inner .funfact .funfact_content span {
      font-size: 4rem;
    }
  }
  
  @media (min-width: 1400px) {
    .funfacts_inner .funfact .funfact_content span {
      line-height: 4rem;
    }
  }
  
  .funfacts_inner .funfact .funfact_content p {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: calc(1.28125rem + 0.32143vw);
    margin-top: calc(1.28125rem + 0.32143vw);
    color: #ffffff;
  }
  
  @media (min-width: 1400px) {
    .funfacts_inner .funfact .funfact_content p {
      line-height: 1.5625rem;
    }
  }
  
  @media (min-width: 1400px) {
    .funfacts_inner .funfact .funfact_content p {
      margin-top: 1.5625rem;
    }
  }
  
.details_link {
    font-size: 0.6875rem;
    font-weight: 600;
    line-height: 1.375rem;
    text-transform: uppercase;
    color: var(--main-o-color);
    margin-bottom: 0px;
    transition-delay: 0.9s;
  }
  
  .details_link a {
    color: var(--main-o-color);
    display: flex;
    align-items: center;
  }
  
  .details_link a .link_text {
    margin-right: 1.875rem;
  }
  
  .details_link a .link_icon {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .details_link a .link_icon .line {
    position: absolute;
    right: 50%;
    top: 49.4%;
    width: 2.1875rem;
    height: 0.0625rem;
    display: inline-block;
    background-color: var(--main-o-color);
    z-index: 1;
    transform-origin: right;
    transition: all 0.5s ease-in-out;
  }
  
  .details_link a .link_icon .circle {
    position: relative;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 100%;
    display: inline-block;
    border: 1px solid var(--main-o-color);
  }
  
  .details_link a .link_icon .dot {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 100%;
    display: inline-block;
    background-color: var(--main-o-color);
  }
  
  .details_link a:hover .link_icon .line {
    animation: scaleAnim1 2s infinite linear;
  }
  
  .details_link a:hover .link_icon .circle {
    animation: rotateAnim 7s infinite linear;
    border-style: dashed;
  }
  
  .details_link a:hover .link_icon .dot {
    animation: scaleAnim2 2s infinite ease-in-out;
  }
  
  .details_link.l-dir a .link_icon .line {
    right: auto;
    left: calc(50% + 5px);
    transform-origin: left;
  }
  
  .details_link.l-dir a .link_icon .dot {
    width: 0px;
    height: 0px;
    border-radius: 0;
    display: inline-block;
    background-color: transparent;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 0px solid transparent;
    border-right: 10px solid var(--main-o-color);
    animation: none;
  }
  
  .details_link.r-dir a .link_icon .line {
    left: auto;
    right: calc(50% + 5px);
    transform-origin: right;
  }
  
  .details_link.r-dir a .link_icon .dot {
    width: 0px;
    height: 0px;
    border-radius: 0;
    display: inline-block;
    background-color: transparent;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 10px solid var(--main-o-color);
    border-right: 0px solid transparent;
    animation: none;
  }
  body.bg-dark.bg-white .theme_slider_3 .details_link.swiper-button-prev a .link_icon .line, body.bg-dark.bg-white .theme_slider_3 .details_link.swiper-button-next a .link_icon .line,
body.bg-dark.bg-white .theme_slider_8 .details_link.swiper-button-prev a .link_icon .line,
body.bg-dark.bg-white .theme_slider_8 .details_link.swiper-button-next a .link_icon .line,
body.bg-dark.bg-white .theme_slider_9 .details_link.swiper-button-prev a .link_icon .line,
body.bg-dark.bg-white .theme_slider_9 .details_link.swiper-button-next a .link_icon .line, body.bg-white .theme_slider_3 .details_link.swiper-button-prev a .link_icon .line, body.bg-white .theme_slider_3 .details_link.swiper-button-next a .link_icon .line,
body.bg-white .theme_slider_8 .details_link.swiper-button-prev a .link_icon .line,
body.bg-white .theme_slider_8 .details_link.swiper-button-next a .link_icon .line,
body.bg-white .theme_slider_9 .details_link.swiper-button-prev a .link_icon .line,
body.bg-white .theme_slider_9 .details_link.swiper-button-next a .link_icon .line {
  background-color: #000000;
}

body.bg-dark.bg-white .theme_slider_3 .details_link.swiper-button-prev a .link_icon .circle, body.bg-dark.bg-white .theme_slider_3 .details_link.swiper-button-next a .link_icon .circle,
body.bg-dark.bg-white .theme_slider_8 .details_link.swiper-button-prev a .link_icon .circle,
body.bg-dark.bg-white .theme_slider_8 .details_link.swiper-button-next a .link_icon .circle,
body.bg-dark.bg-white .theme_slider_9 .details_link.swiper-button-prev a .link_icon .circle,
body.bg-dark.bg-white .theme_slider_9 .details_link.swiper-button-next a .link_icon .circle, body.bg-white .theme_slider_3 .details_link.swiper-button-prev a .link_icon .circle, body.bg-white .theme_slider_3 .details_link.swiper-button-next a .link_icon .circle,
body.bg-white .theme_slider_8 .details_link.swiper-button-prev a .link_icon .circle,
body.bg-white .theme_slider_8 .details_link.swiper-button-next a .link_icon .circle,
body.bg-white .theme_slider_9 .details_link.swiper-button-prev a .link_icon .circle,
body.bg-white .theme_slider_9 .details_link.swiper-button-next a .link_icon .circle {
  border-color: #000000;
}

body.bg-dark.bg-white .theme_slider_3 .details_link.l-dir a .link_icon .dot,
body.bg-dark.bg-white .theme_slider_8 .details_link.l-dir a .link_icon .dot,
body.bg-dark.bg-white .theme_slider_9 .details_link.l-dir a .link_icon .dot, body.bg-white .theme_slider_3 .details_link.l-dir a .link_icon .dot,
body.bg-white .theme_slider_8 .details_link.l-dir a .link_icon .dot,
body.bg-white .theme_slider_9 .details_link.l-dir a .link_icon .dot {
  border-right: 18px solid #000000;
}

body.bg-dark.bg-white .theme_slider_3 .details_link.r-dir a .link_icon .dot,
body.bg-dark.bg-white .theme_slider_8 .details_link.r-dir a .link_icon .dot,
body.bg-dark.bg-white .theme_slider_9 .details_link.r-dir a .link_icon .dot, body.bg-white .theme_slider_3 .details_link.r-dir a .link_icon .dot,
body.bg-white .theme_slider_8 .details_link.r-dir a .link_icon .dot,
body.bg-white .theme_slider_9 .details_link.r-dir a .link_icon .dot {
  border-left: 18px solid #000000;
}
/* loader */
.loaderFather {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
}
.loader {
    border: 4px solid #f3f3f3; /* Light gray border */
    border-top: 4px solid #3498db; /* Blue border for the spinning part */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  