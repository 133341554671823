.popupImage {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    background: #000;
}

.popupIcon {
    position: relative;
    z-index: 9999;
    left: 50px;
    top: 30px;
    font-size: 30px;
}