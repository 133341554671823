body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html:lang(ar) * {
  direction: rtl !important;
  letter-spacing: 1px !important;
}
html:lang(ar) #bauen-aside {
  left: auto;
  right: 0;
}
html:lang(ar) #bauen-main {
  float: left;
}
html:lang(ar) .popupIcon {
  left: auto;
  right: 50px;
}

html:lang(ar) .owl-stage-outer , html:lang(ar) .owl-carousel .owl-stage-outer{
  direction: ltr !important;
} 
html:lang(ar) .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner {
  text-align: right;
} 
html:lang(ar) .loaderFather {
  direction: ltr !important;
} 
